import { useState } from 'react';

import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useDossierAdminContext } from './DossierAdminContext';

interface fetchCourrierNotificationResult {
  fetchCourrierNotification: () => void;
}

interface fetchArPndNotificationResult {
  fetchArPndNotification: () => void;
}

export function useFetchCourrierNotification(
  dossierId: string,
): fetchCourrierNotificationResult {
  const { AdminFonctionnelControllerApi } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const behaviourOnError = useHandleBackErrors();
  const [{ setDossier }, { fetchEvents }] = useDossierAdminContext();

  const fetDossierAdmin = () => {
    void AdminFonctionnelControllerApi.getInformationsByDossierIdUsingGET(
      dossierId,
    ).then(setDossier);
  };

  const fetchCourrierNotification = () => {
    setLoading(true);

    AdminFonctionnelControllerApi.fetchCourrierUsingPOST(dossierId)
      .then(() => {
        void fetchEvents();
        fetDossierAdmin();
      })
      .catch(errorResponse => {
        behaviourOnError(errorResponse, true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    fetchCourrierNotification: fetchCourrierNotification,
  };
}

export function useFetchArPndNotification(
  dossierId: string,
): fetchArPndNotificationResult {
  const { AdminFonctionnelControllerApi } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const behaviourOnError = useHandleBackErrors();
  const [{ setDossier }, { fetchEvents }] = useDossierAdminContext();

  const fetchDossierAdmin = () => {
    void AdminFonctionnelControllerApi.getInformationsByDossierIdUsingGET(
      dossierId,
    ).then(setDossier);
  };

  const fetchArPndNotification = () => {
    setLoading(true);

    AdminFonctionnelControllerApi.fetchArPndUsingPOST(dossierId)
      .then(() => {
        void fetchEvents();
        fetchDossierAdmin();
      })
      .catch(errorResponse => {
        behaviourOnError(errorResponse, true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    fetchArPndNotification: fetchArPndNotification,
  };
}
