import React from 'react';

import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { StatusContent } from './StatutContent/StatusContent';
import { LABEL_DONNEE_EXPIREE, LABEL_SANS_PLAQUE } from 'utils/global';

import './MainInfoHeader.less';

export const MainInfoHeader = (): React.ReactElement => {
  const [{ result: dossier }] = useDossierAdminContext();

  return (
    <div className="MainInfoHeader">
      {dossier.immatriculation ? (
        <span className="Immatriculation">{dossier.immatriculation}</span>
      ) : (
        <span className="Immatriculation SansPlaque">
          {dossier.anonymise ? LABEL_DONNEE_EXPIREE : LABEL_SANS_PLAQUE}
        </span>
      )}

      <span>
        <StatusContent status={dossier.status} />
      </span>
      <span className="TextBox Branche">{dossier.currentBranche}</span>
      {dossier.interdictionCirculer && (
        <span className="TextBox InterdictionCirculer">
          Interdiction de circuler
        </span>
      )}
      {dossier.volConfirme && <span className="TextBox vol">Vol</span>}
    </div>
  );
};
