import React, { useCallback, useEffect, useState } from 'react';

import {
  AutoriteFourriereDto,
  AdminFonctionnelControllerApi,
  AutoriteFourriereRequestDto,
  AutoriteFourriereViolationDtoFormFieldEnum,
  ConditionsReactivateAutoriteFourriereDto,
  ReferentielSearchResultDtoAutoriteFourriereDto,
  ReferentielUsesDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { AutoriteFourriereSortOptions } from 'enums/referentiels/AutoriteFourriere';
import { FilterDashboardAutoriteFourriere } from 'types/referentiels/AutoriteFourriere';
import {
  FetchDataResult,
  TableHeaderFunctionWithoutSort,
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { AutoriteFourriereFormValues } from './type';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { searchAutoriteFourriereUsingSpecs } from 'search/searchAutoriteFourriere';
import { SubmitFunction, ValidateFunction } from 'components/BaseForm/types';

export const buildHeader: TableHeaderFunctionWithoutSort<
  AutoriteFourriereDto,
  FilterDashboardAutoriteFourriere
> = (fetchedData, _selectedData, filter, setFilter) => (
  <DashboardHeader
    totalCount={fetchedData !== undefined ? fetchedData.total : 0}
    filter={filter}
    setFilter={setFilter}
  />
);

/**
 * Hook sent to generic search hook to send search request and convert returned result to readable data
 */
export function useSearchAutoriteFourriere(
  filters: FilterDashboardAutoriteFourriere,
  sorts: TableSort<AutoriteFourriereSortOptions>,
  pagination: TablePagination,
): [
  () => Promise<ReferentielSearchResultDtoAutoriteFourriereDto>,
  (
    result: ReferentielSearchResultDtoAutoriteFourriereDto,
  ) => FetchDataResult<AutoriteFourriereDto>,
] {
  const controller = useApi().ReferentielControllerApi;

  return [
    () =>
      searchAutoriteFourriereUsingSpecs(controller, filters, sorts, pagination),
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

export function useChangeValidityAutoriteFourriere(
  controller: AdminFonctionnelControllerApi,
  currentCommentaires: string | null,
): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<ConditionsReactivateAutoriteFourriereDto>,
  (idCorrelation: string) => Promise<ReferentielUsesDto>,
] {
  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateAutoriteFourriereUsingPUT(idCorrelation, {
        commentaires: currentCommentaires === '' ? null : currentCommentaires,
      });
    },
    [controller, currentCommentaires],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateAutoriteFourriereUsingPUT(idCorrelation, {
        commentaires: currentCommentaires === '' ? null : currentCommentaires,
      });
    },
    [controller, currentCommentaires],
  );

  const conditionsReactivateReferentiel = useCallback(
    (
      idCorrelation: string,
    ): Promise<ConditionsReactivateAutoriteFourriereDto> => {
      return controller.conditionReactivationAutoriteFourriereUsingGET(
        idCorrelation,
      );
    },
    [controller],
  );

  const getUsesRequest = useCallback(
    (idCorrelation: string): Promise<ReferentielUsesDto> => {
      return controller.searchUsesAutoriteFourriereUsingGET(idCorrelation);
    },
    [controller],
  );

  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    getUsesRequest,
  ];
}

export const buildFormValues = (
  autorite: AutoriteFourriereDto,
): AutoriteFourriereFormValues => {
  return {
    numeroDepartement: autorite.numeroDepartement,
    libelleDepartement: autorite.libelleDepartement,
    insee: autorite.codeInsee ?? undefined,
    idCorrelationType: autorite.type.idCorrelation,
    libelle: autorite.libelleComplet,
    adresse: autorite.voie,
    codePostal: autorite.codePostal,
    ville: autorite.ville,
    numeroVoie: autorite.numeroVoie ?? undefined,
    complement: autorite.complementAdresse ?? undefined,
    cedex: autorite.cedex ?? undefined,
    commentaires: autorite.commentaires ?? undefined,
    idHermes: autorite.idHermes ?? undefined,
    idHermesUtilisateur: autorite.idHermesUtilisateur ?? undefined,
  };
};

export const buildRequestDto = (
  values: AutoriteFourriereFormValues,
): AutoriteFourriereRequestDto => {
  return {
    numeroDepartement: values.numeroDepartement || null,
    libelleDepartement: values.libelleDepartement || null,
    libelle: values.libelle || null,
    codePostal: values.codePostal || null,
    numeroVoie: values.numeroVoie || null,
    voie: values.adresse || null,
    ville: values.ville || null,
    insee: values.insee || null,
    complement: values.complement || null,
    cedex: values.cedex || null,
    idCorrelationType: values?.idCorrelationType || null,
    idHermes: values.idHermes || null,
    idHermesUtilisateur: values.idHermesUtilisateur || null,
    commentaires: values.commentaires || null,
  };
};

export function useValidateUpdateAutoriteFourriere(
  autoriteFourriereId: string,
): ValidateFunction<
  AutoriteFourriereFormValues,
  AutoriteFourriereViolationDtoFormFieldEnum
> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: AutoriteFourriereViolationDtoFormFieldEnum,
      values: AutoriteFourriereFormValues,
    ) => {
      const response =
        await controller.validateUpdateAutoriteFourriereFieldUsingPOST(
          field,
          autoriteFourriereId,
          buildRequestDto(values),
        );

      const violations = response.autoriteFourriereViolationsDto ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitUpdateAutoriteFourriere(
  autoriteFourriereId: string,
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<AutoriteFourriereFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: AutoriteFourriereFormValues) => {
      setInProgress(true);
      return controller
        .updateAutoriteFourriereUsingPUT(
          autoriteFourriereId,
          buildRequestDto(values),
        )
        .then(closeModal)
        .then(refreshDashboard)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}

export function useValidateCreateAutoriteFourriere(): ValidateFunction<
  AutoriteFourriereFormValues,
  AutoriteFourriereViolationDtoFormFieldEnum
> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: AutoriteFourriereViolationDtoFormFieldEnum,
      values: AutoriteFourriereFormValues,
    ) => {
      const response =
        await controller.validateCreateAutoriteFourriereFieldUsingPOST(
          field,
          buildRequestDto(values),
        );

      const violations = response.autoriteFourriereViolationsDto ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitCreateAutoriteFourriere(
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<AutoriteFourriereFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: AutoriteFourriereFormValues) => {
      setInProgress(true);
      return controller
        .createAutoriteFourriereUsingPOST(buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}
