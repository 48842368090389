import { InfosSivPersonneRattacheeRequestDto } from 'lib_api/lib/api/gen';

import { InfosSivPersonneRattacheeFormValues } from '../types';

export function infosPersonneRattacheeFormValuesToRequestDto(
  infosPersonneRattachee: InfosSivPersonneRattacheeFormValues,
): InfosSivPersonneRattacheeRequestDto {
  return {
    codeTypePersonne: infosPersonneRattachee.codeTypePersonne || null,
    boitePostale: infosPersonneRattachee.boitePostale || null,
    codeCedex: infosPersonneRattachee.codeCedex || null,
    codePostal: infosPersonneRattachee.codePostal || null,
    complementAdresse: infosPersonneRattachee.complementAdresse || null,
    extension: infosPersonneRattachee.extension || null,
    libelleCedex: infosPersonneRattachee.libelleCedex || null,
    libelleCommune: infosPersonneRattachee.libelleCommune || null,
    libelleVoie: infosPersonneRattachee.libelleVoie || null,
    lieuDit: infosPersonneRattachee.lieuDit || null,
    nomUsage: infosPersonneRattachee.nomUsage || null,
    nomNaissance: infosPersonneRattachee.nomNaissance || null,
    numeroVoie: infosPersonneRattachee.numeroVoie || null,
    pays: infosPersonneRattachee.pays || null,
    pointRemise: infosPersonneRattachee.pointRemise || null,
    prenom: infosPersonneRattachee.prenom || null,
    raisonSociale: infosPersonneRattachee.raisonSociale || null,
    typeVoie: infosPersonneRattachee.typeVoie || null,
  };
}

export function formValueIsEmptyOrUndefined(
  value: string | undefined,
): boolean {
  return value === undefined || value.length === 0;
}
