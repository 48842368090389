import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  ReferentielCommentairesRequestDto,
  ReferentielUsesDto,
  ReferentielViolationsDto,
} from 'lib_api/lib/api/gen';

import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import { defaultErrorMessage } from 'utils/ErrorMessages';
import {
  FetchDataFunctionWithoutArgument,
  FetchDataFunctionWithoutSort,
  FetchDataResult,
  FetchDataUnpagedFunctionWithoutSort,
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';

/**
 * This hook is used to validate complete form before submit if
 * @param validationRequest Function with validation request
 * @param validationKey Key of ReferentielViolationsDto containing form errors
 * @param submitRequest Function with submit request
 * @param onSubmitSuccess Function applied if submit succeed
 * @param onSubmitError Function applied if submit fails
 * @returns [
 *  Methode used to validate and submit request
 *  Violations returned by validation request
 * ]
 */
export function useValidateAndSubmit<
  DtoType,
  Key extends keyof ReferentielViolationsDto,
>(
  validationRequest: () => Promise<ReferentielViolationsDto>,
  validationKey: Key,
  submitRequest: () => Promise<DtoType>,
  onSubmitSuccess: (result: DtoType) => void,
  onSubmitError: (errorResponse: Response) => void,
): [() => Promise<void>, ReferentielViolationsDto[Key] | null] {
  const [violations, setViolations] = useState<
    ReferentielViolationsDto[Key] | null
  >(null);

  return [
    async (): Promise<void> => {
      try {
        const violationResponse = await validationRequest();
        const violations: ReferentielViolationsDto[Key] | null =
          violationResponse[validationKey];

        setViolations(violations);
        if (violations !== null && violations.length === 0) {
          await submitRequest().then(onSubmitSuccess).catch(onSubmitError);
        }
      } catch (e) {
        if (e instanceof Response) {
          return Promise.reject((await backAlertMessage(e)).description);
        }
        return Promise.reject(defaultErrorMessage);
      }

      return Promise.resolve();
    },
    violations,
  ];
}

export function useChangeValidity<ResponseType>(
  idCorrelation: string,
  callAfterSubmit: () => void,
  controllerRequest: (
    idCorrelation: string,
    referentielCommentairesRequestDto: ReferentielCommentairesRequestDto,
  ) => Promise<ResponseType>,
): [() => void, boolean] {
  const behaviourOnError = useHandleBackErrors();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitReactivation = useCallback(() => {
    setIsLoading(true);
    controllerRequest(idCorrelation, { commentaires: null })
      .then(callAfterSubmit)
      .catch((errorResponse: Response) => {
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    setIsLoading,
    callAfterSubmit,
    idCorrelation,
    controllerRequest,
    behaviourOnError,
  ]);
  return [submitReactivation, isLoading];
}

type ConditionsReactivateDtoType<ConditionType> = [
  ConditionType | undefined,
  boolean,
  boolean,
];

export function useConditionsReactivate<ConditionType>(
  idCorrelation: string,
  controllerRequest: (idCorrelation: string) => Promise<ConditionType>,
  conditionUnlock: (conditions: ConditionType) => boolean,
): ConditionsReactivateDtoType<ConditionType> {
  const behaviourOnError = useHandleBackErrors();
  const [conditions, setConditions] = useState<ConditionType | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [unlockedReactivate, setUnlockedReactivate] = useState<boolean>(false);

  useEffect(() => {
    if (conditions === undefined) {
      setIsLoading(true);
      controllerRequest(idCorrelation)
        .then(fetchedConditions => {
          setConditions(fetchedConditions);
          if (conditionUnlock(fetchedConditions)) {
            setUnlockedReactivate(true);
          }
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    conditionUnlock,
    controllerRequest,
    idCorrelation,
    behaviourOnError,
    conditions,
  ]);
  return [conditions, isLoading, unlockedReactivate];
}

type ReferentielUsesType = [ReferentielUsesDto | undefined, boolean, boolean];

export const useReferentielUses = (
  idCorrelation: string,
  controllerRequest:
    | ((idCorrelation: string) => Promise<ReferentielUsesDto>)
    | undefined,
): ReferentielUsesType => {
  const behaviourOnError = useHandleBackErrors();
  const [uses, setUses] = useState<ReferentielUsesDto | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [unlockedDeprecate, setUnlockedDeprecate] = useState<boolean>(false);

  useEffect(() => {
    if (controllerRequest === undefined) {
      setUnlockedDeprecate(true);
    }
    if (uses === undefined && controllerRequest !== undefined) {
      setIsLoading(true);
      controllerRequest(idCorrelation)
        .then(fetchedUses => {
          setUses(fetchedUses);
          if (
            (fetchedUses.listChildrenUniteIdCorrelation?.length ?? 0) === 0 &&
            (fetchedUses.searchDossierResult?.total ?? 0) === 0
          ) {
            setUnlockedDeprecate(true);
          }
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [controllerRequest, idCorrelation, behaviourOnError, uses]);
  return [uses, isLoading, unlockedDeprecate];
};

/** Type returned by useReferentielDashboardSearch */
export type ReferentielSearchType<DtoType, FilterType> = {
  fetchData: FetchDataFunctionWithoutSort<DtoType, FilterType>;
  pagination: TablePagination;
  setPagination: Dispatch<SetStateAction<TablePagination>>;
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>;
  refreshDashboard: () => void;
};

/** Type returned by useReferentielDashboardUnpagedSearch */
export type ReferentielUnpagedSearchType<DtoType, FilterType> = {
  fetchData: FetchDataUnpagedFunctionWithoutSort<DtoType, FilterType>;
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>;
  refreshDashboard: () => void;
};

/**
 * Type of hook sending search request to fill dashboard data and to convert request result to readable data for dashboard
 */
type HookSearchType<DtoType, FilterType, ReferentielSearchResultDto> = (
  filter: FilterType,
  pagination: TablePagination,
) => [
  () => Promise<ReferentielSearchResultDto>,
  (result: ReferentielSearchResultDto) => FetchDataResult<DtoType>,
];

/**
 * Same as HookSearchType but without pagination
 */
type HookUnpagedSearchType<DtoType, FilterType, ReferentielSearchResultDto> = (
  filter: FilterType,
) => [
  () => Promise<ReferentielSearchResultDto>,
  (result: ReferentielSearchResultDto) => FetchDataResult<DtoType>,
];

/**
 * This hook return all utils to build and search data for a referentiel
 * DtoType: Type of referentiel used
 * FilterType: Type of filter object
 * ReferentielSearchResultDto: Type of returned object by API
 *
 * @param hookSearchReferentiel Hook wich send request and convert result to readable data by dashboard
 * @param defaultFilter Filter set when we load dashboard
 * @returns {
 *  fetchData: function used to fetch API data in dashboard
 *  pagination: current pagination in dashboard
 *  setPagination: useEffect pagination setter
 *  filter: current filter in dashboard
 *  setFilter: useEffect filter setter
 *  refreshDashboard: reload search result in dashboard
 * }
 */
export function useReferentielDashboardSearch<
  DtoType,
  FilterType,
  ReferentielSearchResultDto,
>(
  hookSearchReferentiel: HookSearchType<
    DtoType,
    FilterType,
    ReferentielSearchResultDto
  >,
  defaultFilter: FilterType,
): ReferentielSearchType<DtoType, FilterType> {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);

  const [filter, setFilter] = useState<FilterType>(defaultFilter);

  const [searchReferentiel, convertResultToFetchDataResult] =
    hookSearchReferentiel(filter, pagination);

  return {
    fetchData: async () => {
      const result = await searchReferentiel().catch((e: Response) => {
        void backAlertMessage(e);
        throw e;
      });
      return convertResultToFetchDataResult(result);
    },
    pagination: pagination,
    setPagination: newPagination => {
      setPagination(newPagination);
    },
    filter: filter,
    setFilter: setFilter,
    refreshDashboard: () => {
      setFilter({ ...filter });
    },
  };
}

/**
 * This hook return all utils to build and search data for a referentiel
 * DtoType: Type of referentiel used
 * FilterType: Type of filter object
 * ReferentielSearchResultDto: Type of returned object by API
 *
 * @param hookSearchReferentiel Hook wich send request and convert result to readable data by dashboard
 * @param defaultFilter Filter set when we load dashboard
 * @returns {
 *  fetchData: function used to fetch API data in dashboard
 *  pagination: current pagination in dashboard
 *  setPagination: useEffect pagination setter
 *  filter: current filter in dashboard
 *  setFilter: useEffect filter setter
 *  refreshDashboard: reload search result in dashboard
 * }
 */
export function useReferentielDashboardUnpagedSearch<
  DtoType,
  FilterType,
  ReferentielSearchResultDto,
>(
  hookSearchReferentiel: HookUnpagedSearchType<
    DtoType,
    FilterType,
    ReferentielSearchResultDto
  >,
  defaultFilter: FilterType,
): ReferentielUnpagedSearchType<DtoType, FilterType> {
  const [filter, setFilter] = useState<FilterType>(defaultFilter);

  const [searchReferentiel, convertResultToFetchDataResult] =
    hookSearchReferentiel(filter);

  return {
    fetchData: async () => {
      const result = await searchReferentiel().catch((e: Response) => {
        void backAlertMessage(e);
        throw e;
      });
      return convertResultToFetchDataResult(result);
    },
    filter: filter,
    setFilter: setFilter,
    refreshDashboard: () => {
      setFilter({ ...filter });
    },
  };
}

/**
 * Type returned by useCreateOrUpdateReferentiel
 *
 * values: Form values in useState
 * setValues: Function wich set form values
 * validateAndSubmit: Function wich submit form after validate it
 * closeAndResetModal: Function to be used to close modal
 */
export type ReferentielUpdateOrCreateType<FormValues> = {
  values: FormValues;
  setValues: (values: FormValues) => void;
  validateAndSubmit: (values: FormValues) => Promise<void>;
  closeAndResetModal: () => void;
};

/**
 * This hook is used to send creation or update request for a referentiel
 *
 * @param initialValues Initial form values (set when we update modal)
 * @param controllerValidate Function return promise wich validate form
 * @param validationKey Key of ValidationReferentielsDto containing violations
 * @param controllerSubmit Function return promise wich submit form
 * @param closeModal Change modal visibility to false
 * @param refreshDashboard Refresh referentiel dashboard
 * @returns ReferentielUpdateOrCreateType with usefull data for modal
 */
export function useCreateOrUpdateReferentiel<
  DtoType,
  FormValues,
  Key extends keyof ReferentielViolationsDto,
>(
  initialValues: FormValues,
  controllerValidate: (values: FormValues) => Promise<ReferentielViolationsDto>,
  validationKey: Key,
  controllerSubmit: (requestGenerator: FormValues) => Promise<DtoType>,
  closeModal: () => void,
  refreshDashboard: () => void,
): ReferentielUpdateOrCreateType<FormValues> {
  const behaviourOnError = useHandleBackErrors();
  const [values, setValues] = useState<FormValues>(initialValues);

  const submitRequest = useCallback(
    (formValues: FormValues): Promise<DtoType> => {
      return controllerSubmit(formValues);
    },
    [controllerSubmit],
  );

  const validateRequest = useCallback(
    (formValues: FormValues): Promise<ReferentielViolationsDto> => {
      return controllerValidate(formValues);
    },
    [controllerValidate],
  );

  const onSuccess = useCallback((): void => {
    closeModal();
    refreshDashboard();
    setValues(initialValues);
  }, [closeModal, refreshDashboard, initialValues]);

  const onError = useCallback(
    (errorResponse: Response): void => {
      behaviourOnError(errorResponse);
    },
    [behaviourOnError],
  );

  const memoSubmit = useCallback(async () => {
    return submitRequest(values);
  }, [values, submitRequest]);

  const memoValidate = useCallback(async () => {
    return validateRequest(values);
  }, [values, validateRequest]);

  const [validateAndSubmit] = useValidateAndSubmit(
    memoValidate,
    validationKey,
    memoSubmit,
    onSuccess,
    onError,
  );

  return {
    values: values,
    setValues: newValues => {
      setValues(newValues);
    },
    validateAndSubmit: validateAndSubmit,
    closeAndResetModal: () => {
      closeModal();
      setValues(initialValues);
    },
  };
}

/** Type returned by useReferentielDashboardSearch */
export type ReferentielWithoutArgumentType<
  DtoType,
  FilterType,
  SortType extends string | undefined,
> = {
  fetchData: FetchDataFunctionWithoutArgument<DtoType>;
  pagination: TablePagination;
  setPagination: Dispatch<SetStateAction<TablePagination>>;
  filter: FilterType;
  setFilter: Dispatch<SetStateAction<FilterType>>;
  sort: TableSort<SortType>;
  setSort: Dispatch<SetStateAction<TableSort<SortType>>>;
  refreshDashboard: () => void;
};

/**
 * Type of hook sending search request to fill dashboard data and to convert request result to readable data for dashboard
 */
type HookWithoutArgumentType<
  DtoType,
  FilterType,
  SortType extends string | undefined,
  ReferentielSearchResultDto,
> = (
  filter: FilterType,
  sort: TableSort<SortType>,
  pagination: TablePagination,
) => [
  () => Promise<ReferentielSearchResultDto>,
  (result: ReferentielSearchResultDto) => FetchDataResult<DtoType>,
];

/**
 * This hook return all utils to build and search data for a referentiel
 * DtoType: Type of referentiel used
 * FilterType: Type of filter object
 * ReferentielSearchResultDto: Type of returned object by API
 *
 * @param hookSearchReferentiel Hook wich send request and convert result to readable data by dashboard
 * @param defaultFilter Filter set when we load dashboard
 * @returns {
 *  fetchData: function used to fetch API data in dashboard
 *  pagination: current pagination in dashboard
 *  setPagination: useEffect pagination setter
 *  filter: current filter in dashboard
 *  setFilter: useEffect filter setter
 *  refreshDashboard: reload search result in dashboard
 * }
 */
export function useReferentielDashboardWithoutArgument<
  DtoType,
  FilterType,
  SortType extends string | undefined,
  ReferentielSearchResultDto,
>(
  hookSearchReferentiel: HookWithoutArgumentType<
    DtoType,
    FilterType,
    SortType,
    ReferentielSearchResultDto
  >,
  defaultFilter: FilterType,
  defaultSort: TableSort<SortType>,
): ReferentielWithoutArgumentType<DtoType, FilterType, SortType> {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);

  const [filter, setFilter] = useState<FilterType>(defaultFilter);
  const [sort, setSort] = useState<TableSort<SortType>>(defaultSort);

  const [searchReferentiel, convertResultToFetchDataResult] =
    hookSearchReferentiel(filter, sort, pagination);

  return {
    fetchData: async () => {
      const result = await searchReferentiel().catch((e: Response) => {
        void backAlertMessage(e);
        throw e;
      });
      return convertResultToFetchDataResult(result);
    },
    pagination: pagination,
    setPagination: newPagination => {
      setPagination(newPagination);
    },
    filter: filter,
    setFilter: setFilter,
    sort: sort,
    setSort: setSort,
    refreshDashboard: () => {
      setFilter({ ...filter });
    },
  };
}
