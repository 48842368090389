import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

import {
  AlienationInformationDtoDecisionEnum,
  BrancheDtoBrancheEnum,
  MainLeveeDtoGradeAgentEnum,
  MainLeveeDtoJustificationSortieEnum,
  MainLeveeDtoTypeRestitutionEnum,
  MainLeveeViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import BaseForm from 'components/BaseForm/BaseForm';
import BaseInput from 'components/BaseForm/BaseInput';
import BaseDatePicker from 'components/BaseForm/BaseDatePicker';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import AutocompleteCommune from 'components/BaseForm/Autocomplete/AutocompleteCommune';
import { getValueFromEventContainingOnlyNumeric } from 'components/BaseForm/utils';
import BaseSelectMarqueMultiList from 'components/Dropdown/BaseSelectMarqueMultiList';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import { useApi } from 'hooks/ApiStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useFetchDonneesMainlevee } from 'hooks/dossiers/useFetchDonneesMainlevee';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { fetchGenresimplifie } from 'search/searchGenreSimplifie';
import { fetchFourriere } from 'search/searchFourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import {
  gradesAgent,
  justificationsSortie,
  typesRestitution,
} from 'utils/enumData';
import useSubmitMainlevee from './buildSubmitFunction';
import MainLeveeBanner from './MainLeveeBanner';
import { MainLevee } from './types';
import WarningMainLevee from './WarningMainLevee';
import {
  buildDefaultMainLeveeValues,
  buildFetchProgressionInfo,
  validateMainleveeField,
} from './utils';
import { useFullMarques } from './useFullMarques';

interface MainLeveeFormProps {
  close: () => void;
}

function hasInterdictionCirculer(values: MainLevee) {
  return values.interdictionCirculer === true;
}

function hasNoInterdictionCirculer(values: MainLevee) {
  return !hasInterdictionCirculer(values);
}

const MainLeveeForm = ({ close }: MainLeveeFormProps): React.ReactElement => {
  // Extract dossier values
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const decisionHermes = dossier.body?.alienationInformationDto?.decision;

  const behaviourOnError = useHandleBackErrors();

  // Retrieve API controller
  const { ForceOrdreControllerApi, ReferentielControllerApi } = useApi();
  // Retrieve Mainlevee data
  const [
    mainlevee,
    fetch,
    inProgressFetchMainlevee,
    hasMainleveeFetchErrorOccured,
  ] = useFetchDonneesMainlevee(ForceOrdreControllerApi, dossierId);
  // Retrieve Marques data
  const {
    fullMarques: fullMarques,
    marquesPrio: marquesPrio,
    search: search,
    paginate: paginate,
    inProgressFetchMarques: inProgressFetchMarques,
    inProgressFetchMarquesPrio: inProgressFetchMarquesPrio,
    inProgressFetchInitialMarque: inProgressFetchInitialMarque,
    hasInitialMarqueFetchErrorOccured: hasInitialMarqueFetchErrorOccured,
  } = useFullMarques(mainlevee);
  // Build fetch progression info
  const {
    isDataFetchInProgress,
    hasDataFetchErrorOccured,
    isMarquesFetchInProgress,
  } = buildFetchProgressionInfo(
    inProgressFetchMainlevee,
    inProgressFetchMarques,
    inProgressFetchInitialMarque,
    inProgressFetchMarquesPrio,
    hasMainleveeFetchErrorOccured,
    hasInitialMarqueFetchErrorOccured,
  );

  const branche: BrancheDtoBrancheEnum | null =
    dossier.body?.traitement?.branches?.at(-1)?.branche ?? null;

  // Submit mainlevee form
  const { submit, inProgress } = useSubmitMainlevee(
    ForceOrdreControllerApi,
    dossierId,
    setDossier,
    close,
  );

  const isRemisAuDomaine = () => {
    return (
      decisionHermes &&
      decisionHermes !==
        AlienationInformationDtoDecisionEnum.REFUSE_OU_ECHEC_DE_VENTE
    );
  };

  const isBrancheAdministrative: boolean =
    branche === BrancheDtoBrancheEnum.ADMINISTRATIVE;

  const warningMessageAdministratif = {
    title: "Édition de mainlevée dans le cas d'une procédure administrative",
    detail:
      'La sortie d’un véhicule visé par une procédure administrative est un cas exceptionnel répondant à des caractéristiques spécifiques',
    style: { maxWidth: '80%' },
  };

  return (
    <AsyncComponent
      reload={fetch}
      inProgress={isDataFetchInProgress}
      errorOccured={hasDataFetchErrorOccured}
      render={() => {
        if (mainlevee) {
          return (
            <>
              <MainLeveeBanner mainlevee={mainlevee} />
              <div className="modalContent">
                <h3 className={'mainTitle'}>Mainlevée</h3>
                {isRemisAuDomaine() && !mainlevee.acheteur && (
                  <WarningMainLevee />
                )}
                {isBrancheAdministrative && (
                  <WarningMessage {...warningMessageAdministratif} />
                )}
                <BaseForm
                  initialValues={buildDefaultMainLeveeValues(mainlevee)}
                  isSubmitting={inProgress}
                  onChange={(_changed, formValues) => {
                    if (formValues.interdictionCirculer !== true) {
                      formValues.dateDebutPermissionProvisoire = undefined;
                      formValues.dateFinPermissionProvisoire = undefined;
                      formValues.motifInterdictionCirculer = undefined;
                    }

                    return formValues;
                  }}
                  validateField={validateMainleveeField(
                    dossierId,
                    ForceOrdreControllerApi,
                    behaviourOnError,
                  )}
                  onSubmit={submit}
                  onCancel={close}
                  inputs={[
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.NUM_PROCES_VERBAL,
                      name: 'numProcesVerbal',
                      label: 'Numéro de procès verbal',
                      getValueFromEvent: getValueFromEventContainingOnlyNumeric,
                      render: () => {
                        return (
                          <BaseInput placeholder={FormPlaceholders.Figures} />
                        );
                      },
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.DATE_PROCES_VERBAL,
                      required: true,
                      name: 'dateProcesVerbal',
                      label: 'Date du procès verbal',
                      render: () => {
                        return <BaseDatePicker />;
                      },
                    },
                    {
                      field: MainLeveeViolationDtoFormFieldEnum.GRADE_AGENT,
                      required: true,
                      name: 'gradeAgent',
                      label: "Grade de l'agent rédigeant la mainlevée",
                      render: () => {
                        return (
                          <BaseSelect
                            options={Object.values(MainLeveeDtoGradeAgentEnum)}
                            getOptionValue={option => option}
                            getOptionLabel={option => gradesAgent[option].label}
                          />
                        );
                      },
                    },
                    {
                      name: 'nomAgent',
                      label: "Nom de l'agent rédigeant la mainlevée",
                    },
                    {
                      field: MainLeveeViolationDtoFormFieldEnum.MATRICULE_AGENT,
                      name: 'matriculeAgent',
                      label: "Matricule de l'agent rédigeant la mainlevée",
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.COMMUNE_FONCTION,
                      required: true,
                      name: 'communeFonctionAgent',
                      label: "Commune de fonction de l'agent",
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.JUSTIFICATION_SORTIE,
                      required: isBrancheAdministrative,
                      name: 'justificationSortie',
                      label: 'Justification de sortie',
                      displayItem: (values: MainLevee) => {
                        return isBrancheAdministrative;
                      },
                      render: () => {
                        return (
                          <BaseSelect
                            options={Object.values(
                              MainLeveeDtoJustificationSortieEnum,
                            )}
                            getOptionValue={option => option}
                            getOptionLabel={option =>
                              justificationsSortie[option].label
                            }
                          />
                        );
                      },
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.TYPE_RESTITUTION,
                      required: true,
                      name: 'typeRestitution',
                      label: 'Type de restitution',
                      render: () => {
                        return (
                          <BaseSelect
                            options={Object.values(
                              MainLeveeDtoTypeRestitutionEnum,
                            )}
                            getOptionValue={option => option}
                            getOptionLabel={option =>
                              typesRestitution[option].label
                            }
                          />
                        );
                      },
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.GENRE_SIMPLIFIE_ID,
                      required: true,
                      name: 'idCorrelationGenreSimplifie',
                      label: 'Genre du véhicule',
                      render: () => {
                        return (
                          <AsyncSelect
                            fetchOptions={() => {
                              return fetchGenresimplifie(
                                ReferentielControllerApi,
                              );
                            }}
                            getOptionValue={genre => genre.idCorrelation}
                            getOptionLabel={genre => genre.libelle}
                          />
                        );
                      },
                    },
                    {
                      field: MainLeveeViolationDtoFormFieldEnum.IMMATRICULATION,
                      required: true,
                      name: 'immatriculation',
                      label: 'Immatriculation / identification du véhicule',
                    },
                    {
                      field: MainLeveeViolationDtoFormFieldEnum.MARQUE_ID,
                      required: true,
                      name: 'idMarque',
                      label: 'Marque du véhicule',
                      render: () => {
                        return (
                          <BaseSelectMarqueMultiList
                            marques={fullMarques}
                            inProgress={isMarquesFetchInProgress}
                            onSearch={search}
                            paginate={paginate}
                            marquesPrio={marquesPrio}
                          />
                        );
                      },
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.NOM_PROPRIETAIRE,
                      required: true,
                      name: 'nomProprietaire',
                      label: 'Nom du propriétaire ou conducteur',
                    },
                    {
                      name: 'prenomProprietaire',
                      label: 'Prénom du propriétaire ou conducteur',
                    },
                    {
                      name: 'rsProprietaire',
                      label: 'Raison sociale du propriétaire ou conducteur',
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.LIEU_ADRESSE_PROPRIETAIRE,
                      required: true,
                      name: 'lieuAdresseProprietaire',
                      label: 'Adresse propriétaire ou conducteur',
                      render: ({ name }) => {
                        return (
                          <AutocompleteAdresse
                            name={name}
                            communeField="communeAdresseProprietaire"
                            codePostalField="codePostalProprietaire"
                          />
                        );
                      },
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.CODE_POSTAL_PROPRIETAIRE,
                      required: true,
                      name: 'codePostalProprietaire',
                      label: 'Code postal du propriétaire ou conducteur',
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.COMMUNE_ADRESSE_PROPRIETAIRE,
                      required: true,
                      name: 'communeAdresseProprietaire',
                      label: 'Commune adresse propriétaire ou conducteur',
                      render: props => {
                        return <AutocompleteCommune {...props} />;
                      },
                    },
                    {
                      field: MainLeveeViolationDtoFormFieldEnum.FOURRIERE_ID,
                      required: true,
                      name: 'idCorrelationFourriere',
                      label: 'Nom de la fourrière',
                      render: () => {
                        return (
                          <AsyncSelect
                            fetchOptions={() => {
                              return fetchFourriere(ReferentielControllerApi);
                            }}
                            getOptionLabel={fourriere =>
                              `${fourriere.codePostal} - ${fourriere.raisonSociale}`
                            }
                            getOptionValue={fourriere =>
                              fourriere.idCorrelation
                            }
                            showSearch={true}
                          />
                        );
                      },
                    },
                    {
                      name: 'interdictionCirculer',
                      label: 'Présence interdiction circuler',
                      valuePropName: 'checked',
                      shouldUpdate: true,
                      render: () => {
                        return <AntdCheckbox />;
                      },
                    },
                    {
                      disabled: hasNoInterdictionCirculer,
                      field:
                        MainLeveeViolationDtoFormFieldEnum.DEBUT_AUTORISATION,
                      dependencies: ['interdictionCirculer'],
                      name: 'dateDebutPermissionProvisoire',
                      label: 'Date début permission provisoire',
                      render: ({ disabled }) => {
                        return <BaseDatePicker disabled={disabled} />;
                      },
                    },
                    {
                      disabled: hasNoInterdictionCirculer,
                      field:
                        MainLeveeViolationDtoFormFieldEnum.FIN_AUTORISATION,
                      dependencies: ['interdictionCirculer'],
                      name: 'dateFinPermissionProvisoire',
                      label: 'Date de fin de permission provisoire',
                      render: ({ disabled }) => {
                        return <BaseDatePicker disabled={disabled} />;
                      },
                    },
                    {
                      disabled: hasNoInterdictionCirculer,
                      name: 'motifInterdictionCirculer',
                      label: "Motif de l'interdiction de circuler",
                      render: ({ disabled }) => {
                        return <BaseInput disabled={disabled} />;
                      },
                    },
                    {
                      field:
                        MainLeveeViolationDtoFormFieldEnum.COMMUNE_REDACTION,
                      required: true,
                      name: 'communeRedaction',
                      label: 'Fait à',
                      render: props => {
                        return <AutocompleteCommune {...props} />;
                      },
                    },
                  ]}
                />
              </div>
            </>
          );
        }

        return <></>;
      }}
    />
  );
};

export default MainLeveeForm;
