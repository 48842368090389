import React from 'react';

import {
  AutoriteFourriereControllerApi,
  UpdateEntitesRattachementViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import {
  UpdateEntiteRattachementFormValues,
  ValidateFunction,
} from 'components/UpdateEntitesRattachements/types';

function buildValidateFunction(
  refController: AutoriteFourriereControllerApi,
  dossierId: string,
): ValidateFunction {
  return (
    values: UpdateEntiteRattachementFormValues,
    key: keyof UpdateEntiteRattachementFormValues,
    field: UpdateEntitesRattachementViolationDtoFormFieldEnum,
    value: unknown,
  ) => {
    const request = {
      ...values,
      [key]: value,
    };

    return refController.validateUpdateEntitesRattachementFieldAFUsingPOST(
      field,
      dossierId,
      request,
    );
  };
}

export default buildValidateFunction;
