import React from 'react';
import { Button as AntdButton } from 'antd';

import AutocompleteCommune from 'components/BaseForm/Autocomplete/AutocompleteCommune';
import BaseForm from 'components/BaseForm/BaseForm';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import { typePersonneMapping } from 'utils/enumData';
import { InfosSivPersonneRattacheeFormValues } from '../types';
import { formValueIsEmptyOrUndefined } from './utils';
import { InfosSivPersonneRattacheeRequestDtoCodeTypePersonneEnum } from 'lib_api/lib/api/gen';

interface InfosPersonneRattacheeFormProps {
  initialValues: InfosSivPersonneRattacheeFormValues;
  setValues: (newData: InfosSivPersonneRattacheeFormValues) => void;
  goPreviousForm: () => void;
  goNextForm: () => void;
}

export default function InfosPersonneRattacheeForm({
  initialValues,
  setValues,
  goPreviousForm,
  goNextForm,
}: InfosPersonneRattacheeFormProps): React.ReactElement {
  const warningMessage = {
    title: 'Conséquence de la modification',
    detail:
      "Les données saisies par l'administrateur seront considérées comme identiques aux données connues du SIV. Ces informations seront utilisées comme telles sur l'ensemble du workflow de mise en fourrière.",
  };

  return (
    <BaseForm
      initialValues={initialValues}
      onSubmit={values => {
        setValues(values);
        goNextForm();
      }}
      inputs={[
        {
          label: 'Type de personne',
          name: 'codeTypePersonne',
          required: true,
          render: () => {
            return (
              <BaseSelect
                options={Object.values(
                  InfosSivPersonneRattacheeRequestDtoCodeTypePersonneEnum,
                )}
                getOptionValue={option => option}
                getOptionLabel={option => typePersonneMapping[option]}
              />
            );
          },
        },
        {
          label: 'Prénom',
          name: 'prenom',
        },
        {
          label: "Nom d'usage",
          name: 'nomUsage',
          required: (values: InfosSivPersonneRattacheeFormValues) => {
            return (
              formValueIsEmptyOrUndefined(values.nomNaissance) &&
              formValueIsEmptyOrUndefined(values.raisonSociale)
            );
          },
        },
        {
          label: 'Nom de naissance',
          name: 'nomNaissance',
          required: (values: InfosSivPersonneRattacheeFormValues) => {
            return (
              formValueIsEmptyOrUndefined(values.nomUsage) &&
              formValueIsEmptyOrUndefined(values.raisonSociale)
            );
          },
        },
        {
          label: 'Raison sociale',
          name: 'raisonSociale',
          required: (values: InfosSivPersonneRattacheeFormValues) => {
            return (
              formValueIsEmptyOrUndefined(values.nomUsage) &&
              formValueIsEmptyOrUndefined(values.nomNaissance)
            );
          },
        },
        {
          label: 'Numéro de voie',
          name: 'numeroVoie',
        },
        {
          label: 'Extension',
          name: 'extension',
        },
        {
          label: 'Type de voie',
          name: 'typeVoie',
          required: true,
        },
        {
          label: 'Libellé de voie',
          name: 'libelleVoie',
          required: true,
        },
        {
          label: "Complément d'adresse",
          name: 'complementAdresse',
        },
        {
          label: 'Lieu-dit',
          name: 'lieuDit',
        },
        {
          label: 'Boîte postale',
          name: 'boitePostale',
        },
        {
          label: 'Code cedex',
          name: 'codeCedex',
        },
        {
          label: 'Libellé cedex',
          name: 'libelleCedex',
        },
        {
          label: 'Code postal',
          name: 'codePostal',
          required: true,
        },
        {
          label: 'Commune',
          name: 'libelleCommune',
          required: true,
          render: ({ name }) => {
            return (
              <AutocompleteCommune codePostalField="codePostal" name={name} />
            );
          },
        },
        {
          label: 'Point remise',
          name: 'pointRemise',
        },
        {
          label: 'Pays',
          name: 'pays',
        },
      ]}
      warningMessage={warningMessage}
      submitText="Suivant"
      renderButtonAction={SubmitButton => {
        return (
          <div className="navigation">
            <AntdButton
              type={'primary'}
              className={'left'}
              onClick={goPreviousForm}
            >
              Précédent
            </AntdButton>
            <div className="right">{SubmitButton}</div>
          </div>
        );
      }}
    />
  );
}
