import {
  AutocompleteUniteFOResultDto,
  AutocompleteUniteFOResultDtoFieldEnum,
  ReferentielSearchResultDtoUniteFOAdminResponseDto,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import useAsyncSearch from 'hooks/search/async/useAsyncSearch';
import {
  useAdminSearchUniteFo,
  useSearchUniteFo,
} from 'hooks/search/useSearchUniteFo';
import { BaseSearchResult, SearchResult } from 'types/searchResult';

export default function useAsyncSearchAdminUniteFo(
  idCorrelation?: string,
): SearchResult {
  const mapResult = (
    result: ReferentielSearchResultDtoUniteFOAdminResponseDto,
  ) => {
    return result.results.referenceDtoList.map(uniteFo => {
      return {
        value: uniteFo.idCorrelation,
        displayValue: uniteFo.abreviation,
      };
    });
  };
  const updateFilters = (query?: string) => {
    return { abreviation: query };
  };
  const defaultFilter = { idCorrelation };

  return useAsyncSearch(
    useAdminSearchUniteFo,
    mapResult,
    updateFilters,
    defaultFilter,
  );
}

function getInitialSearchQuery(
  field: AutocompleteUniteFOResultDtoFieldEnum,
  initialUnite?: UniteFOResponseDto,
): string {
  if (!initialUnite) {
    return '';
  }

  switch (field) {
    case AutocompleteUniteFOResultDtoFieldEnum.UNITE:
      return initialUnite.unite;
    case AutocompleteUniteFOResultDtoFieldEnum.ABREVIATION:
      return initialUnite.abreviation;
    case AutocompleteUniteFOResultDtoFieldEnum.CODE:
      return initialUnite.code;
    default:
      return '';
  }
}

export function useAsyncSearchUniteFo(
  field: AutocompleteUniteFOResultDtoFieldEnum,
  initialUnite?: UniteFOResponseDto,
): () => BaseSearchResult<UniteFOResponseDto> {
  return () => {
    const mapResult = (result: AutocompleteUniteFOResultDto) => {
      const uniteFoList = result.reference.referenceDtoList;
      if (
        uniteFoList.filter(
          unite => unite.idCorrelation === initialUnite?.idCorrelation,
        ).length === 0
      ) {
        return uniteFoList.concat(initialUnite ? [initialUnite] : []);
      }

      return uniteFoList;
    };
    const updateFilters = (query?: string) => {
      return { search: query ?? '', field: field };
    };

    const defaultFilter = {
      search: getInitialSearchQuery(field, initialUnite),
      field: field,
    };

    return useAsyncSearch(
      useSearchUniteFo,
      mapResult,
      updateFilters,
      defaultFilter,
    );
  };
}
