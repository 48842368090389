import React, { ReactElement } from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';

import { useConditionsReactivate, useChangeValidity } from '../../utils';

import './ModalReactivation.less';

export interface ConditionReactivateProps<ConditionType> {
  conditions: ConditionType | undefined;
}

interface ModalReactivationProps<ConditionType, ResponseType> {
  idCorrelation: string;
  visible: boolean;
  titleModal: string;
  close: () => void;
  callAfterSubmit: () => void;
  conditionRequest: (idCorrelation: string) => Promise<ConditionType>;
  reactivateRequest: (idCorrelation: string) => Promise<ResponseType>;
  componentConditions: ({
    conditions,
  }: ConditionReactivateProps<ConditionType>) => React.ReactElement;
  conditionUnlock: (conditions: ConditionType | undefined) => boolean;
  children?: ReactElement;
}

function ModalReactivation<ConditionType, ResponseType>({
  idCorrelation,
  titleModal,
  visible,
  close,
  callAfterSubmit,
  conditionRequest,
  componentConditions,
  conditionUnlock,
  reactivateRequest,
  children,
}: ModalReactivationProps<ConditionType, ResponseType>): React.ReactElement {
  const [conditions, isLoadingConditions, unlockedReactivation] =
    useConditionsReactivate(idCorrelation, conditionRequest, conditionUnlock);

  const [submitReactivation, isLoadingSubmit] = useChangeValidity(
    idCorrelation,
    () => {
      close();
      callAfterSubmit();
    },
    reactivateRequest,
  );

  return (
    <AntdModal
      className="modalValidity"
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
    >
      <h3>{titleModal}</h3>
      {children}
      {!conditionUnlock(conditions) &&
        componentConditions({ conditions: conditions })}
      <div className="modalActions">
        <AntdButton onClick={close}>Annuler</AntdButton>
        <AntdButton
          type="primary"
          loading={isLoadingSubmit || isLoadingConditions}
          disabled={!unlockedReactivation || isLoadingSubmit}
          onClick={submitReactivation}
        >
          Confirmer la réactivation
        </AntdButton>
      </div>
    </AntdModal>
  );
}

export default ModalReactivation;
