import React, { ReactElement } from 'react';

import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useDossierCompletAdmin } from 'hooks/dossierAdmin/useDownloadDossierAdminFonctionnel';
import RenseignerInformationsSivForm from './RenseignerInformationsSivForm/RenseignerInformationsSivForm';

interface RenseignerInformationsSivProps {
  close: () => void;
}

export default function RenseignerInformationsSiv({
  close,
}: RenseignerInformationsSivProps): ReactElement {
  const [{ result, setDossier }, { fetchEvents }] = useDossierAdminContext();
  const dossierId = result.id;
  const {
    dossier: dossierAdmin,
    fetchInProgress,
    fetchDossier,
    errorOccured,
  } = useDossierCompletAdmin(dossierId);

  return (
    <AsyncComponent
      render={() => {
        return dossierAdmin ? (
          <RenseignerInformationsSivForm
            dossier={dossierAdmin}
            dossierId={dossierId}
            setDossier={setDossier}
            fetchEvents={fetchEvents}
            close={close}
          />
        ) : null;
      }}
      inProgress={fetchInProgress}
      errorOccured={errorOccured}
      reload={fetchDossier}
      errorMessage="Impossible de charger les données du dossier, veuillez réessayer"
    />
  );
}
