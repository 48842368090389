import React, { useMemo } from 'react';

import { DossierResponseDto } from 'lib_api/lib/api/gen';

import { UpdateEntiteRattachementFormValues } from './types';

function useInitialValues(
  dossier: DossierResponseDto | undefined,
): UpdateEntiteRattachementFormValues {
  const idCorrelationFourriere =
    dossier?.body?.ficheFourriere?.idCorrelationFourriere ?? null;
  const idCorrelationAutoriteFourriere =
    dossier?.body?.ficheInfraction?.idCorrelationAutoriteFourriere ?? null;
  const idCorrelationUniteFo =
    dossier?.body?.ficheInfraction?.idCorrelationUniteFO ?? null;

  return useMemo(() => {
    return {
      newAutoriteFourriereIdCorrelation: idCorrelationAutoriteFourriere,
      newFourriereIdCorrelation: idCorrelationFourriere,
      newUniteFoIdCorrelation: idCorrelationUniteFo,
      comment: null,
    };
  }, [
    idCorrelationFourriere,
    idCorrelationAutoriteFourriere,
    idCorrelationUniteFo,
  ]);
}

export default useInitialValues;
