import { useState } from 'react';

import { DossierResponseDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { backAlertMessage } from '../utils/backAlertMessage';

export function useValidateActionsVvnrHorsSif(
  dossierId: string,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
): [() => void, boolean] {
  const { ForceOrdreControllerApi } = useApi();
  const [inProgress, setProgress] = useState(false);
  return [
    () => {
      setProgress(true);
      return ForceOrdreControllerApi.validateActionsHorsSifUsingPOST(dossierId)
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setProgress(false);
        });
    },
    inProgress,
  ];
}
