import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { DossierResponseDto } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { profileEnumMapping } from 'utils/enumData';
import useInitialValues from 'components/UpdateEntitesRattachements/useInitialValues';
import UpdateEntiteRattachement from 'components/UpdateEntitesRattachements/UpdateEntiteRattachement';
import buildValidateFunction from './buildValidateFunction';
import buildSubmitFunction from './buildSubmitFunction';

interface UpdateEntiteRattachementAFProps {
  closeModal: () => void;
}

function UpdateEntiteRattachementAF({
  closeModal,
}: UpdateEntiteRattachementAFProps): React.ReactElement {
  const user = useUserStore();
  const { homePath } = profileEnumMapping[user.profile];
  const navigate = useNavigate();
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const controller = useApi().AutoriteFourriereControllerApi;
  const dossierController = useApi().DossierControllerApi;
  const initialValues = useInitialValues(dossier);

  const setDossierOrGoBack = (dossierReponse: DossierResponseDto) => {
    if (
      dossierReponse.body?.ficheInfraction?.idCorrelationAutoriteFourriere !==
      user.idCorrelationAutoriteFourriere
    ) {
      navigate(homePath);
    } else {
      setDossier(dossierReponse);
    }
  };

  const validateFunction = useCallback(
    buildValidateFunction(controller, dossierId),
    [controller, dossierId],
  );
  const submitFunction = useCallback(
    buildSubmitFunction(
      controller,
      dossierController,
      dossierId,
      setDossierOrGoBack,
      closeModal,
    ),
    [controller, dossierId],
  );

  return (
    <UpdateEntiteRattachement
      closeModal={closeModal}
      initialValues={initialValues}
      loading={!dossier}
      submitFunction={submitFunction}
      validateFunction={validateFunction}
    />
  );
}

export default UpdateEntiteRattachementAF;
