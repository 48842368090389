import React from 'react';

import { Button as AntdButton, Modal as AntdModal } from 'antd';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useValidateActionsVvnrHorsSif } from 'hooks/dossiers/useValidateActionsVvnrHorsSif';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';

import './ValidationActionsVvnrHorsSif.less';

interface ValidationActionsVvnrHorsSifProps {
  visible: boolean;
  close: () => void;
}

export const ValidationActionsVvnrHorsSif = ({
  visible,
  close,
}: ValidationActionsVvnrHorsSifProps): React.ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const [submit, submitInProgress] = useValidateActionsVvnrHorsSif(
    dossierId,
    setDossier,
    close,
  );

  return (
    <AntdModal
      open={visible}
      onCancel={close}
      destroyOnClose={true}
      footer={null}
      width={700}
      maskClosable={false}
    >
      <div className="validationVvnrHorsSif">
        <h3>Accompagner la restitution d&apos;un véhicule volé</h3>
        <p>
          Ce dossier a été <b>identifié comme volé</b> suite à une recherche
          menée par l’assureur. Plusieurs actions sont attendues de votre part
          pour finaliser la récupération du véhicule par le propriétaire.
        </p>
        <p>
          <b>Afin de débloquer la situation, vous devez :</b>
          <ul>
            <li>
              <b>Lever la mention “vol”</b> apposée sur le dossier dans le
              fichier FOVES
            </li>
            <li>
              <b>Lever le motif de l’infraction</b> ou vous assurer que le
              propriétaire effectue une contestation de l’infraction ayant mené
              à la mise en fourrière (dans le cas où la mise en fourrière date
              de plus de 4 jours)
            </li>
          </ul>
        </p>

        <p className="validationVvnrHorsSifConsequence">
          <span>
            <b>Conséquence de l’action</b>
          </span>
          <br />
          <span>
            Si le véhicule est maintenu avec la mention “Vol“ au fichier Foves,
            son état ne permet pas la récupération par son propriétaire. En
            cliquant sur “Confirmer”, vous considérez comme finalisées
            l’ensemble des actions précitées.
          </span>
        </p>
        <div className="buttonsContainer">
          <AntdButton onClick={close}>Annuler</AntdButton>
          <AntdButton
            type="primary"
            disabled={submitInProgress}
            onClick={submit}
          >
            Confirmer
          </AntdButton>
        </div>
      </div>
    </AntdModal>
  );
};
