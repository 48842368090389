import {
  AdminFonctionnelControllerApi,
  DossierControllerApi,
  DossierDtoAdminFonctionnel,
  DossierResponseDto,
  ErrorDto,
  ErrorDtoCodeEnum,
} from 'lib_api/lib/api/gen';
import { openNotification } from 'hooks/utils/backAlertMessage';
import { backErrorMessages } from 'utils/ErrorMessages';

const notificationMessage =
  'Doublon détecté lors de la mise à jour de la fourrière de rattachement';

export async function displayNotificationForAdminIfDoublonOrElseThrow(
  response: Response,
  dossierController: AdminFonctionnelControllerApi,
  dossierId: string,
): Promise<DossierDtoAdminFonctionnel> {
  const error: ErrorDto = await response.json();
  if (
    error.code ===
    ErrorDtoCodeEnum.DOSSIER_UPDATE_FOURRIERE_RATTACHEMENT_DOUBLON_DOSSIER_ERROR
  ) {
    openNotification(
      notificationMessage,
      {
        description: backErrorMessages[error.code],
      },
      false,
    );
    return dossierController.getInformationsByDossierIdUsingGET(dossierId);
  }
  throw response;
}
export async function displayNotificationForOthersIfDoublonOrElseThrow(
  response: Response,
  dossierController: DossierControllerApi,
  dossierId: string,
): Promise<DossierResponseDto> {
  const error: ErrorDto = await response.json();
  if (
    error.code ===
    ErrorDtoCodeEnum.DOSSIER_UPDATE_FOURRIERE_RATTACHEMENT_DOUBLON_DOSSIER_ERROR
  ) {
    openNotification(
      notificationMessage,
      {
        description: backErrorMessages[error.code],
      },
      false,
    );
    return dossierController.getByIdUsingGET(dossierId);
  }
  throw response;
}
