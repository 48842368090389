import React from 'react';

import { MarqueDto } from 'lib_api/lib/api/gen';

import BaseForm from 'components/BaseForm/BaseForm';
import BaseDatePicker from 'components/BaseForm/BaseDatePicker';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import SelectModeleByIdCorrelationMarque from 'components/BaseForm/Select/SelectModeleByIdCorrelationMarque';
import { useApi } from 'hooks/ApiStoreContext';
import { useAsyncSearchMarque } from 'hooks/search/async/useAsyncSearchMarque';
import { fetchGenresimplifie } from 'search/searchGenreSimplifie';
import { LABEL_MARQUE_NON_REFERENCEE, VALUE_NON_REFERENCE } from 'utils/global';
import { InfosSivVehiculeFormValues } from '../types';

interface InfosVehiculeFormProps {
  goNextForm: () => void;
  initialValues: InfosSivVehiculeFormValues;
  setValues: (newData: InfosSivVehiculeFormValues) => void;
}

export default function InfosVehiculeForm({
  goNextForm,
  initialValues,
  setValues,
}: InfosVehiculeFormProps): React.ReactElement {
  const { ReferentielControllerApi: refController } = useApi();

  const warningMessage = {
    title: 'Conséquence de la modification',
    detail:
      "Les données saisies par l'administrateur seront considérées comme identiques aux données connues du SIV. Ces informations seront utilisées comme telles sur l'ensemble du workflow de mise en fourrière.",
  };

  return (
    <BaseForm
      initialValues={initialValues}
      onSubmit={values => {
        setValues(values);
        goNextForm();
      }}
      onChange={(changed, all: InfosSivVehiculeFormValues) => {
        return {
          ...all,
          idCorrelationModele: changed.marque
            ? undefined
            : all.idCorrelationModele,
        };
      }}
      inputs={[
        {
          label: "Numéro d'immatriculation",
          name: 'numeroImmatriculation',
          required: true,
          normalize: (value: string) => value.toUpperCase(),
        },
        {
          label: 'VIN',
          name: 'vin',
        },
        {
          label: 'Date de première immatriculation',
          name: 'datePremiereImmatriculation',
          required: true,
          render: () => {
            return <BaseDatePicker />;
          },
        },
        {
          label: 'CNIT',
          name: 'cnit',
        },
        {
          label: 'Marque',
          name: 'marque',
          required: true,
          render: () => {
            return (
              <BaseSelectWithSearch
                useSearchEntities={useAsyncSearchMarque(['EXACTE', 'INCONNUE'])}
                getOptionValue={(marque: MarqueDto | null) =>
                  marque ? marque.idCorrelation || '' : VALUE_NON_REFERENCE
                }
                getOptionLabel={(marque: MarqueDto | null) =>
                  marque ? marque.libelle : LABEL_MARQUE_NON_REFERENCEE
                }
              />
            );
          },
        },
        {
          label: 'Modèle',
          name: 'idCorrelationModele',
          render: () => {
            return <SelectModeleByIdCorrelationMarque idMarqueField="marque" />;
          },
        },
        {
          label: 'Genre SIV',
          name: 'idCorrelationGenre',
          required: true,
          render: () => {
            return (
              <AsyncSelect
                fetchOptions={() => {
                  return fetchGenresimplifie(refController);
                }}
                getOptionValue={genre => genre.idCorrelation}
                getOptionLabel={genre => genre.libelle}
                showSearch={true}
              />
            );
          },
        },
      ]}
      warningMessage={warningMessage}
      submitText="Suivant"
    />
  );
}
