import React, { ReactElement, useState } from 'react';
import { Steps as AntdSteps } from 'antd';
import Icon, { FileTextOutlined, UserOutlined } from '@ant-design/icons';

import {
  DossierDtoAdminFonctionnel,
  ExportDonneesDossierAdminFonctionnelDto,
} from 'lib_api/lib/api/gen';

import { CarIcon } from 'components/Icon';

import InfosVehiculeForm from '../InfosVehiculeForm/InfosVehiculeForm';
import InfosPersonneRattacheeForm from '../InfosPersonneRattacheeForm/InfosPersonneRattacheeForm';
import InfosDocumentAndSituationForm from '../InfosDocumentsAndSituationForm/InfosDocumentAndSituationForm';
import {
  InfosSivDocumentsAndSituationAdministrativeFormValues,
  InfosSivFormValues,
  InfosSivPersonneRattacheeFormValues,
  InfosSivVehiculeFormValues,
} from '../types';
import { initialInfosSivFormValues } from '../utils';

import './RenseignerInformationsSivForm.less';

interface RenseignerInformationsSivProps {
  dossier: ExportDonneesDossierAdminFonctionnelDto;
  dossierId: string;
  close: () => void;
  fetchEvents: () => Promise<void>;
  setDossier: (dossier: DossierDtoAdminFonctionnel) => void;
}

export default function RenseignerInformationsSivForm({
  dossier,
  dossierId,
  close,
  fetchEvents,
  setDossier,
}: RenseignerInformationsSivProps): ReactElement {
  const [indexTab, setIndexTab] = useState(0);
  const [data, setData] = useState<InfosSivFormValues>(
    initialInfosSivFormValues(dossier),
  );
  const onChangeTab = (indexTab: number): void => {
    setIndexTab(Math.min(2, Math.max(indexTab, 0)));
  };
  const goNextTab = (): Promise<void> => {
    return new Promise(() => {
      onChangeTab(indexTab + 1);
    });
  };
  const goPreviousTab = (): Promise<void> => {
    return new Promise(() => {
      onChangeTab(indexTab - 1);
    });
  };

  const onSubmitSuccess = (newDossier: DossierDtoAdminFonctionnel) => {
    setDossier(newDossier);
    void fetchEvents();
    close();
  };

  const infosVehiculeStep = {
    title: 'Véhicule',
    content: (
      <InfosVehiculeForm
        initialValues={data.infosSivVehicule}
        setValues={(values: InfosSivVehiculeFormValues): void => {
          setData(data => {
            return {
              ...data,
              infosSivVehicule: values,
            };
          });
        }}
        goNextForm={goNextTab}
      />
    ),
    icon: (
      <Icon
        component={(): React.ReactElement => (
          <CarIcon width="32px" height="32px" />
        )}
      />
    ),
  };

  const infosPersonneRattacheeStep = {
    title: 'Personne rattachée',
    content: (
      <InfosPersonneRattacheeForm
        initialValues={data.infosSivPersonneRattachee}
        setValues={(values: InfosSivPersonneRattacheeFormValues): void => {
          setData(data => {
            return {
              ...data,
              infosSivPersonneRattachee: values,
            };
          });
        }}
        goPreviousForm={goPreviousTab}
        goNextForm={goNextTab}
      />
    ),
    icon: <UserOutlined />,
  };

  const infosDocumentAndSituationStep = {
    title: 'Document et situation',
    content: (
      <InfosDocumentAndSituationForm
        formValues={data}
        dossierId={dossierId}
        initialValues={data.infosSivDocumentsAndSituationAdministrative}
        setValues={(
          values: InfosSivDocumentsAndSituationAdministrativeFormValues,
        ): void => {
          setData(data => {
            return {
              ...data,
              infosSivDocumentsAndSituationAdministrative: values,
            };
          });
        }}
        goPreviousForm={goPreviousTab}
        onSubmitSuccess={onSubmitSuccess}
      />
    ),
    icon: <FileTextOutlined />,
  };

  const steps = [
    infosVehiculeStep,
    infosPersonneRattacheeStep,
    infosDocumentAndSituationStep,
  ];

  return (
    <div className={'renseignerInformationsSiv'}>
      <AntdSteps type="default" current={indexTab} onChange={onChangeTab}>
        {steps.map((item, index) => (
          <AntdSteps.Step
            key={item.title}
            title={item.title}
            icon={item.icon}
            disabled={index >= indexTab}
          />
        ))}
      </AntdSteps>
      <div className="content">{steps[indexTab].content}</div>
    </div>
  );
}
