import React from 'react';
import {
  AutoriteFourriereDto,
  FourriereViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import TextArea from 'antd/lib/input/TextArea';

import { useApi } from 'hooks/ApiStoreContext';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';
import { FormInputProps } from 'components/BaseForm/types';
import BaseDatePicker from 'components/BaseForm/BaseDatePicker';
import BaseInputNumber from 'components/BaseForm/BaseInputNumber';
import { FourriereFormValues } from 'types/referentiels/Fourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { removeDiacritics } from 'utils/StringUtils';
import { fetchAutoriteFourriere } from 'search/searchAutoriteFourriere';
import ModalEdition from '../../Modals/ModalEdition/ModalEdition';

import './ModalEditionFourriere.less';

interface ModalEditionFourriereProps {
  close: () => void;
  visible: boolean;
  initials: FourriereFormValues;
  title: string;
  submit: (values: FourriereFormValues) => void;
  isSubmitting: boolean;
  validate: (
    field: FourriereViolationDtoFormFieldEnum,
    values: FourriereFormValues,
  ) => Promise<void>;
  additionalInputs?: FormInputProps<
    FourriereFormValues,
    FourriereViolationDtoFormFieldEnum
  >[];
}

function ModalEditionFourriere({
  additionalInputs,
  ...props
}: ModalEditionFourriereProps): React.ReactElement {
  const { ReferentielControllerApi: refController } = useApi();

  const commentaireInput: FormInputProps<
    FourriereFormValues,
    FourriereViolationDtoFormFieldEnum
  > = {
    name: 'commentaires',
    label: 'Commentaires',
    render: () => <TextArea placeholder={FormPlaceholders.Input} />,
  };

  const otherInputs: Array<
    FormInputProps<FourriereFormValues, FourriereViolationDtoFormFieldEnum>
  > = additionalInputs ?? [];

  const baseInputs: Array<
    FormInputProps<FourriereFormValues, FourriereViolationDtoFormFieldEnum>
  > = [
    {
      field: FourriereViolationDtoFormFieldEnum.RAISON_SOCIALE,
      name: 'raisonSociale',
      normalize: (value: string) => value.toUpperCase(),
      dependencies: [
        'numeroVoie',
        'adresse',
        'repetition',
        'codePostal',
        'commune',
      ],
      label: 'Raison sociale',
      required: true,
    },
    {
      name: 'numeroAgrement',
      label: "Numéro d'agrément",
    },
    {
      name: 'dateAgrement',
      label: "Date de l'agrément",
      render: () => {
        return <BaseDatePicker />;
      },
    },
    {
      name: 'finAgrement',
      label: "Date de fin de l'agrément",
      render: () => {
        return <BaseDatePicker />;
      },
    },
    {
      field: FourriereViolationDtoFormFieldEnum.AF_RATTACHEMENT,
      required: true,
      name: 'listAf',
      label: 'Autorité(s) de fourrière',
      render: () => {
        const fetchOptions = () => {
          return fetchAutoriteFourriere(refController);
        };
        const getValue = (autorite: AutoriteFourriereDto) => {
          return autorite.idCorrelation;
        };
        const getLabel = (autorite: AutoriteFourriereDto) => {
          return autorite.libelleComplet;
        };

        return (
          <AsyncSelect
            fetchOptions={fetchOptions}
            getOptionValue={getValue}
            getOptionLabel={getLabel}
            mode="multiple"
            dropdownMatchSelectWidth={350}
          />
        );
      },
    },
    {
      name: 'nbPlaces',
      label: 'Nombre de places',
      render: () => {
        return <BaseInputNumber />;
      },
    },
    {
      name: 'numeroVoie',
      dependencies: [
        'adresse',
        'repetition',
        'raisonSociale',
        'codePostal',
        'commune',
      ],
      label: 'Numéro de voie',
      render: () => {
        return <BaseInputNumber />;
      },
    },
    {
      field: FourriereViolationDtoFormFieldEnum.REPETITION,
      name: 'repetition',
      dependencies: [
        'numeroVoie',
        'adresse',
        'raisonSociale',
        'codePostal',
        'commune',
      ],
      label: 'Répétition',
    },
    {
      field: FourriereViolationDtoFormFieldEnum.ADRESSE,
      name: 'adresse',
      dependencies: [
        'numeroVoie',
        'repetition',
        'raisonSociale',
        'codePostal',
        'commune',
      ],
      label: 'Libellé de voie',
      render: ({ disabled, ...props }) => {
        return (
          <AutocompleteAdresse
            {...props}
            communeField="commune"
            codePostalField="codePostal"
          />
        );
      },
    },
    {
      name: 'complementAdresse',
      label: "Complément d'adresse",
    },
    {
      field: FourriereViolationDtoFormFieldEnum.CODE_POSTAL,
      name: 'codePostal',
      dependencies: [
        'numeroVoie',
        'adresse',
        'repetition',
        'raisonSociale',
        'commune',
      ],
      label: 'Code postal',
      required: true,
    },
    {
      field: FourriereViolationDtoFormFieldEnum.COMMUNE,
      name: 'commune',
      dependencies: [
        'numeroVoie',
        'adresse',
        'repetition',
        'raisonSociale',
        'codePostal',
      ],
      label: 'Commune',
      normalize: (value: string) => value.toUpperCase(),
    },
    {
      field: FourriereViolationDtoFormFieldEnum.TELEPHONE_FIXE,
      name: 'telephoneFixe',
      label: 'Téléphone fixe',
    },
    {
      field: FourriereViolationDtoFormFieldEnum.TELEPHONE_PORTABLE,
      name: 'telephonePortable',
      label: 'Téléphone portable',
    },
    {
      field: FourriereViolationDtoFormFieldEnum.EMAIL,
      name: 'email',
      label: 'Adresse email',
    },
    {
      name: 'gps',
      label: 'Coordonnées GPS',
    },
    {
      field: FourriereViolationDtoFormFieldEnum.RAISON_SOCIALE_DNID,
      name: 'raisonSocialeDnid',
      label: 'Raison sociale DNID',
      required: true,
    },
    {
      name: 'adresseDnid',
      label: 'Adresse DNID',
      normalize: (value: string) => removeDiacritics(value).toUpperCase(),
      renderAfterInput: () => {
        return (
          <div className="DnidMessage">
            Vérifier si le référentiel TOPAD de la DNID est respecté pour le
            type de voie (AV, BD, CHE ...)
          </div>
        );
      },
    },
    {
      name: 'nomResponsable',
      label: 'Nom du responsable',
    },
    {
      field: FourriereViolationDtoFormFieldEnum.EMAIL_RESPONSABLE,
      name: 'emailResponsable',
      label: 'Adresse email du responsable',
    },
  ];

  return (
    <ModalEdition
      {...props}
      inputs={[...baseInputs, ...otherInputs, commentaireInput]}
    />
  );
}

export default ModalEditionFourriere;
