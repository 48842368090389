import { Moment } from 'moment';

import {
  ActionsRequisesDtoActionsRequisesEnum,
  DossierSummaryResponseDto,
  SearchDossierSummariesUsingGETQueryParams,
  SearchDossiersResponseDto,
  TraitementResponseDtoListStatutEnum,
  TraitementSummaryDtoClassementEnum,
} from 'lib_api/lib/api/gen/api';

import { BaseOptionType } from 'components/BaseForm/Select/BaseSelect';
import { baseOptionTypeArrayToStringArray } from 'components/BaseForm/utils';
import { FetchDataFunction } from 'components/WrappedComponents/Table/types';
import { OptionProps } from 'types/searchResult';
import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

/**
 * Since filters used in search request are provided as request parameters (so not as request body),
 * generated client does not provide us a full dto to represent the whole filter. This intermediary type
 * is therefore used in frontend to reprensent the whole filter object that can be used when searching
 * dossiers. Some filters may not be used depending on user profile.
 */
export interface Filters {
  idFourriereList?: string[];
  idAutoriteFourriere?: string;
  idUniteFO?: string;

  numeroImmatriculation?: string;
  classementList?: TraitementSummaryDtoClassementEnum[];
  statutDossierEnumList?: TraitementResponseDtoListStatutEnum[];
  idMotifSortieList: string[];
  idNatureBrancheList?: string[];
  communeEnlevementList?: string[];
  listUniteFo?: string[];
  actionsRequises?: ActionsRequisesDtoActionsRequisesEnum[];
  statutsFacturation?: StatutTraitementFacturation[];

  dateRangeMiseAJour?: [Moment | undefined, Moment | undefined];
  dateRangeMiseEnFourriere?: [Moment | undefined, Moment | undefined];
  dateRangeBonEnlevement?: [Moment | undefined, Moment | undefined];
  dateRangeSortieDefinitive?: [Moment | undefined, Moment | undefined];
  dateRangeAbandon?: [Moment | undefined, Moment | undefined];
  dateRangeOrdreDestruction?: [Moment | undefined, Moment | undefined];

  includeDossiersSortis?: boolean;
  includeDossiersAnonymises?: boolean;
}

export interface AdminFilters {
  idFourriereList?: BaseOptionType<OptionProps>[];
  idAutoriteFourriere?: string;
  idUniteFO?: string;

  numeroImmatriculation?: string;
  classementList?: TraitementSummaryDtoClassementEnum[];
  statutDossierEnumList?: TraitementResponseDtoListStatutEnum[];
  idMotifSortieList: string[];
  idNatureBrancheList?: string[];
  communeEnlevementList?: string[];
  listUniteFo?: BaseOptionType<OptionProps>[];
  actionsRequises?: ActionsRequisesDtoActionsRequisesEnum[];
  statutsFacturation?: StatutTraitementFacturation[];

  dateRangeMiseAJour?: [Moment | undefined, Moment | undefined];
  dateRangeMiseEnFourriere?: [Moment | undefined, Moment | undefined];
  dateRangeBonEnlevement?: [Moment | undefined, Moment | undefined];
  dateRangeSortieDefinitive?: [Moment | undefined, Moment | undefined];
  dateRangeAbandon?: [Moment | undefined, Moment | undefined];
  dateRangeOrdreDestruction?: [Moment | undefined, Moment | undefined];

  includeDossiersSortis?: boolean;
  includeDossiersAnonymises?: boolean;
}

export enum SortOptions {
  'ACTIONS_REQUISES' = 'ACTIONS_REQUISES',
  'CLASSEMENT' = 'CLASSEMENT',
  'COULEUR' = 'COULEUR',
  'FOURRIERE' = 'FOURRIERE',
  'DATE_ABANDON' = 'DATE_ABANDON',
  'DATE_BON_ENLEVEMENT' = 'DATE_BON_ENLEVEMENT',
  'DATE_MISE_FOURRIERE' = 'DATE_MISE_FOURRIERE',
  'DATE_MISE_A_JOUR' = 'DATE_MISE_A_JOUR',
  'DATE_SORTIE_DEFINITIVE' = 'DATE_SORTIE_DEFINITIVE',
  'IMMATRICULATION' = 'IMMATRICULATION',
  'MARQUE' = 'MARQUE',
  'MODELE' = 'MODELE',
  'NATURE_MISE_EN_FOURRIERE' = 'NATURE_MISE_EN_FOURRIERE',
  'STATUT' = 'STATUT',
  'DATE_ORDRE_DESTRUCTION' = 'DATE_ORDRE_DESTRUCTION',
}

export enum StatutTraitementFacturation {
  'TRAITE' = 'TRAITE',
  'NON_TRAITE' = 'NON_TRAITE',
}

/**
 * Hook to filter search for dossier summaries based on given filters.
 * It is especially used to ease the mapping between client's parameters
 * and frontend filters, by providing an intermediary Filter type.
 *
 * Return a function that can be used to fetch data summaries for dashboards.
 */
export function useSearchDossierSummaries(): FetchDataFunction<
  DossierSummaryResponseDto,
  Filters,
  SortOptions
> {
  const controllerApi = useApi().DossierControllerApi;
  const behaviourOnError = useHandleBackErrors();

  return async (pagination, sorters, filters) => {
    const queryParams: SearchDossierSummariesUsingGETQueryParams = {
      dossierCriteriaDto: {
        ...filters,
        dateFromBonEnlevement: filters.dateRangeBonEnlevement?.[0]?.toDate(), // date bon enlevement (début)
        dateFromMiseAJour: filters.dateRangeMiseAJour?.[0]?.toDate(), // date mise à jour (début)
        dateFromMiseEnFourriere:
          filters.dateRangeMiseEnFourriere?.[0]?.toDate(), // date mise en fourrière (début)
        dateFromOrdreDestruction:
          filters.dateRangeOrdreDestruction?.[0]?.toDate(), // date ordre destruction (début)
        dateFromSortieDefinitive:
          filters.dateRangeSortieDefinitive?.[0]?.toDate(), // date sortie (début)
        dateToBonEnlevement: filters.dateRangeBonEnlevement?.[1]?.toDate(), // date bon enlevement (début)
        dateToMiseAJour: filters.dateRangeMiseAJour?.[1]?.toDate(), // date mise à jour (fin)
        dateToMiseEnFourriere: filters.dateRangeMiseEnFourriere?.[1]?.toDate(), // date mise en fourrière (fin)
        dateToOrdreDestruction:
          filters.dateRangeOrdreDestruction?.[1]?.toDate(), // date ordre de destruction (fin)
        dateToSortieDefinitive:
          filters.dateRangeSortieDefinitive?.[1]?.toDate(), // date sortie (fin),
        idUniteFOList: filters.listUniteFo,
        idFourriereList: filters.idFourriereList,
        idMotifSortieList: filters.idMotifSortieList,
        idNatureBrancheList: filters.idNatureBrancheList,
      },
      dossierSortDto: {
        alertesIsAsc: sorters?.ACTIONS_REQUISES,
        classementIsAsc: sorters?.CLASSEMENT,
        couleurIsAsc: sorters?.COULEUR,
        dateBonEnlevementIsAsc: sorters?.DATE_BON_ENLEVEMENT,
        dateMiseAJourIsAsc: sorters?.DATE_MISE_A_JOUR,
        dateMiseFourriereIsAsc: sorters?.DATE_MISE_FOURRIERE,
        dateOrdreDestructionIsAsc: sorters?.DATE_ORDRE_DESTRUCTION,
        dateSortieDefinitiveIsAsc: sorters?.DATE_SORTIE_DEFINITIVE,
        fourriereIsAsc: sorters?.FOURRIERE,
        immatriculationIsAsc: sorters?.IMMATRICULATION,
        marqueIsAsc: sorters?.MARQUE,
        modeleIsAsc: sorters?.MODELE,
        natureMiseEnFourriereIsAsc: sorters?.NATURE_MISE_EN_FOURRIERE,
        statutIsAsc: sorters?.STATUT,
      },
      page: pagination.page - 1, // page (pagination in frontend start at index 1, while backend start at 0)
      pageSize: pagination.pageSize,
    };
    const result: SearchDossiersResponseDto = await controllerApi
      .searchDossierSummariesUsingGET(queryParams)
      .catch((error: Response) => {
        behaviourOnError(error);
        throw error;
      });

    // Convert result to be usable by Table component
    return {
      data: result.dossiers.map((dossier: DossierSummaryResponseDto) => ({
        ...dossier,
        key: dossier.id,
      })),
      total: result.total,
    };
  };
}

export function useAdminSearchDossierSummaries(): FetchDataFunction<
  DossierSummaryResponseDto,
  AdminFilters,
  SortOptions
> {
  const controllerApi = useApi().DossierControllerApi;
  const behaviourOnError = useHandleBackErrors();

  return async (pagination, sorters, filters) => {
    const queryParams: SearchDossierSummariesUsingGETQueryParams = {
      dossierCriteriaDto: {
        ...filters,
        dateFromBonEnlevement: filters.dateRangeBonEnlevement?.[0]?.toDate(), // date bon enlevement (début)
        dateFromMiseAJour: filters.dateRangeMiseAJour?.[0]?.toDate(), // date mise à jour (début)
        dateFromMiseEnFourriere:
          filters.dateRangeMiseEnFourriere?.[0]?.toDate(), // date mise en fourrière (début)
        dateFromOrdreDestruction:
          filters.dateRangeOrdreDestruction?.[0]?.toDate(), // date ordre destruction (début)
        dateFromSortieDefinitive:
          filters.dateRangeSortieDefinitive?.[0]?.toDate(), // date sortie (début)
        dateToBonEnlevement: filters.dateRangeBonEnlevement?.[1]?.toDate(), // date bon enlevement (début)
        dateToMiseAJour: filters.dateRangeMiseAJour?.[1]?.toDate(), // date mise à jour (fin)
        dateToMiseEnFourriere: filters.dateRangeMiseEnFourriere?.[1]?.toDate(), // date mise en fourrière (fin)
        dateToOrdreDestruction:
          filters.dateRangeOrdreDestruction?.[1]?.toDate(), // date ordre de destruction (fin)
        dateToSortieDefinitive:
          filters.dateRangeSortieDefinitive?.[1]?.toDate(), // date sortie (fin),
        idUniteFOList: baseOptionTypeArrayToStringArray(filters.listUniteFo),
        idFourriereList: baseOptionTypeArrayToStringArray(
          filters.idFourriereList,
        ),
        idMotifSortieList: filters.idMotifSortieList,
        idNatureBrancheList: filters.idNatureBrancheList,
      },
      dossierSortDto: {
        alertesIsAsc: sorters?.ACTIONS_REQUISES,
        classementIsAsc: sorters?.CLASSEMENT,
        couleurIsAsc: sorters?.COULEUR,
        dateBonEnlevementIsAsc: sorters?.DATE_BON_ENLEVEMENT,
        dateMiseAJourIsAsc: sorters?.DATE_MISE_A_JOUR,
        dateMiseFourriereIsAsc: sorters?.DATE_MISE_FOURRIERE,
        dateOrdreDestructionIsAsc: sorters?.DATE_ORDRE_DESTRUCTION,
        dateSortieDefinitiveIsAsc: sorters?.DATE_SORTIE_DEFINITIVE,
        fourriereIsAsc: sorters?.FOURRIERE,
        immatriculationIsAsc: sorters?.IMMATRICULATION,
        marqueIsAsc: sorters?.MARQUE,
        modeleIsAsc: sorters?.MODELE,
        natureMiseEnFourriereIsAsc: sorters?.NATURE_MISE_EN_FOURRIERE,
        statutIsAsc: sorters?.STATUT,
      },
      page: pagination.page - 1, // page (pagination in frontend start at index 1, while backend start at 0)
      pageSize: pagination.pageSize,
    };
    const result: SearchDossiersResponseDto = await controllerApi
      .searchDossierSummariesUsingGET(queryParams)
      .catch((error: Response) => {
        behaviourOnError(error);
        throw error;
      });

    // Convert result to be usable by Table component
    return {
      data: result.dossiers.map((dossier: DossierSummaryResponseDto) => ({
        ...dossier,
        key: dossier.id,
      })),
      total: result.total,
    };
  };
}
