import { useMemo } from 'react';

import {
  ExportDonneesDossierAdminFonctionnelDto,
  InfosSivRequestDto,
} from 'lib_api/lib/api/gen';

import { LABEL_MARQUE_NON_REFERENCEE, VALUE_NON_REFERENCE } from 'utils/global';
import {
  infosDocumentFormValuesToRequestDto,
  infosSituationAdministrativeFormValuesToRequestDto,
} from './InfosDocumentsAndSituationForm/utils';
import { infosPersonneRattacheeFormValuesToRequestDto } from './InfosPersonneRattacheeForm/utils';
import { infosVehiculeFormValuesToRequestDto } from './InfosVehiculeForm/utils';
import { InfosSivFormValues } from './types';

export function initialInfosSivFormValues(
  dossier: ExportDonneesDossierAdminFonctionnelDto,
): InfosSivFormValues {
  const genre =
    dossier.dossier.body?.ficheVehicule?.description?.genreSimplifie;
  const modele = dossier.dossier.body?.ficheVehicule?.description?.modele;
  const marque = dossier.dossier.body?.ficheVehicule?.description?.marque;
  const immatriculation =
    dossier?.dossier?.body?.ficheVehicule?.immatriculation?.numero;
  const vin = dossier?.dossier?.body?.ficheVehicule?.immatriculation?.vin;

  return useMemo(() => {
    return {
      infosSivVehicule: {
        idCorrelationGenre: genre?.idCorrelation ?? undefined,
        marque: {
          option: marque ?? undefined,
          label: marque?.libelle ?? LABEL_MARQUE_NON_REFERENCEE,
        },
        idCorrelationModele: modele?.idCorrelation ?? VALUE_NON_REFERENCE,
        numeroImmatriculation: immatriculation ?? undefined,
        vin: vin ?? undefined,
      },
      infosSivPersonneRattachee: {},
      infosSivDocumentsAndSituationAdministrative: {},
    };
  }, [genre, modele, marque, immatriculation, vin]);
}

export function InfosSivFormValuesToRequest(
  values: InfosSivFormValues,
): InfosSivRequestDto {
  return {
    infosSivDocumentRequestDto: infosDocumentFormValuesToRequestDto(
      values.infosSivDocumentsAndSituationAdministrative,
    ),
    infosSivPersonneRattacheeRequestDto:
      infosPersonneRattacheeFormValuesToRequestDto(
        values.infosSivPersonneRattachee,
      ),
    infosSivVehiculeRequestDto: infosVehiculeFormValuesToRequestDto(
      values.infosSivVehicule,
    ),
    infosSivSituationAdministrativeRequestDto:
      infosSituationAdministrativeFormValuesToRequestDto(
        values.infosSivDocumentsAndSituationAdministrative,
      ),
  };
}
