import React from 'react';
import { Button as AntdButton, Checkbox as AntdCheckbox } from 'antd';

import { DossierDtoAdminFonctionnel } from 'lib_api/lib/api/gen';

import BaseDatePicker from 'components/BaseForm/BaseDatePicker';
import BaseForm from 'components/BaseForm/BaseForm';
import {
  InfosSivDocumentsAndSituationAdministrativeFormValues,
  InfosSivFormValues,
} from '../types';
import useSubmitUpdateInfosSiv from '../buildSubmitFunction';

interface InfosDocumentAndSituationFormProps {
  dossierId: string;
  formValues: InfosSivFormValues;
  initialValues: InfosSivDocumentsAndSituationAdministrativeFormValues;
  setValues: (
    newData: InfosSivDocumentsAndSituationAdministrativeFormValues,
  ) => void;
  goPreviousForm: () => void;
  onSubmitSuccess: (newDossier: DossierDtoAdminFonctionnel) => void;
}

export default function InfosDocumentAndSituationForm({
  dossierId,
  formValues,
  initialValues,
  setValues,
  goPreviousForm,
  onSubmitSuccess,
}: InfosDocumentAndSituationFormProps): React.ReactElement {
  const { submit, inProgress } = useSubmitUpdateInfosSiv(onSubmitSuccess);

  const warningMessage = {
    title: 'Conséquence de la modification',
    detail:
      "Les données saisies par l'administrateur seront considérées comme identiques aux données connues du SIV. Ces informations seront utilisées comme telles sur l'ensemble du workflow de mise en fourrière.",
  };

  return (
    <BaseForm
      initialValues={initialValues}
      onSubmit={submitted =>
        submit(dossierId, {
          ...formValues,
          infosSivDocumentsAndSituationAdministrative: submitted,
        })
      }
      onChange={(_changed, all) => {
        setValues(all);
        return all;
      }}
      isSubmitting={inProgress}
      inputs={[
        {
          label: "Date du certificat d'immatriculation",
          name: 'dateCi',
          required: true,
          render: () => {
            return <BaseDatePicker />;
          },
        },
        {
          label: 'Numéro de formule',
          name: 'numeroFormule',
        },
        {
          label: 'PVE',
          name: 'flagPve',
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          label: 'Véhicule volé',
          name: 'flagVehiculeVole',
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
      ]}
      warningMessage={warningMessage}
      renderButtonAction={SubmitButton => {
        return (
          <div className="navigation">
            <AntdButton
              type={'primary'}
              className={'left'}
              onClick={goPreviousForm}
            >
              Précédent
            </AntdButton>
            <div className="right">{SubmitButton}</div>
          </div>
        );
      }}
    />
  );
}
