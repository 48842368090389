import React from 'react';
import { Alert as AntdAlert, Modal as AntdModal } from 'antd';
import {
  ComputedResponseDtoListActionsEnum,
  ComputedResponseDtoListAvailableFilesEnum,
} from 'lib_api/lib/api/gen';
import { useLocation } from 'react-router-dom';

import { ActionsRequisesLinkState } from 'utils/actionsRequisesUtils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useApi } from 'hooks/ApiStoreContext';
import { useFOFilterContext } from 'hooks/FOFilterStoreContext';
import { useRefUniteFo } from 'hooks/referentiels/useRefUniteFo';
import { useDisplayActionsRequisesBanner } from 'hooks/useActionsRequises';
import { useUserStore } from 'hooks/UserStoreContext';
import { useModal } from 'hooks/modal/useModal';
import ActionAndFileWrapper from 'components/ActionAndFileWrapper/ActionAndFileWrapper';
import { AvailableAction } from 'components/ActionAndFileWrapper/types';
import MainLeveeForm from 'components/FicheDescriptiveDisplay/MainLevee/MainLeveeForm';
import CoordonneesProprietaireForm from 'components/FicheDescriptiveDisplay/CoordonneesProprietaire/CoordonneesProprietaireForm';
import ModalProlongation from 'components/FicheDescriptiveDisplay/Prolongation/ModalProlongation';
import ModalControlerDonneesVehicule from 'components/FicheDescriptiveDisplay/ControlerDonneesVehicule/ModalControlerDonneesVehicule';
import { ActionSaisieInfoNotification } from './InfosNotification/ActionSaisieInfoNotification';
import EditionNotificationForm from './EditionNotification/EditionNotificationForm';
import { RelanceVenteVol } from './RelanceVenteVol/RelanceVenteVol';
import ControleBlocageVenteErreurIdentificationVehicule from './BlocageVente/ControleBlocageVenteErreurIdentificationVehicule';
import UpdateEntiteRattachementFO from './UpdateEntitesRattachements/UpdateEntiteRattachementFO';

import './ActionsSuiviVehicule.less';
import { ConfirmationVolVvnr } from './ConfirmationVolVvnr/ConfirmationVolVvnr';
import { ValidationActionsVvnrHorsSif } from './ValidationActionsVvnrHorsSif/ValidationActionsVvnrHorsSif';

const ActionsSuiviVehicule = (): React.ReactElement => {
  const user = useUserStore();
  const { state } = useLocation() as { state: ActionsRequisesLinkState };

  const {
    visible: isMainLeveeOpen,
    open: openMainLevee,
    close: closeMainLeveeOpen,
  } = useModal();

  const {
    visible: isProlongationOpen,
    open: openProlongation,
    close: closeProlongation,
  } = useModal(state?.openProlongationMef);

  const {
    visible: isSaisieInfoNotificationOpen,
    open: openSaisieInfoNotification,
    close: closeSaisieInfoNotification,
  } = useModal(state?.openSuiviNotification);

  const {
    visible: isEditionNotificationOpen,
    open: openEditionNotification,
    close: closeEditionNotification,
  } = useModal();

  const {
    visible: isUpdateInfosProprietaireOpen,
    open: openUpdateInfosProprietaire,
    close: closeUpdateInfosProprietaire,
  } = useModal(state?.openCoordonneesProprietaire);

  const {
    visible: isControlerDonneesVehiculeOpen,
    open: openControlerDonneesVehicule,
    close: closeControlerDonneesVehicule,
  } = useModal(state?.openControlerDonnees);

  const {
    visible: isRelanceVenteVolOpen,
    open: openRelanceVenteVol,
    close: closeRelanceVenteVol,
  } = useModal(state?.openControleBlocageVenteVol);

  const controleBlocageVente = useModal(
    state?.openControleBlocageVenteErreurIdentification,
  );

  const confirmationVolVvnr = useModal(state?.openConfirmationVolVvnr);

  const validationActionsVvnrHorsSif = useModal(
    state?.openValidationActionsVvnrHorsSif,
  );

  const updateEntiteRattachementModal = useModal();

  const foController = useApi().ForceOrdreControllerApi;
  const [dossier] = useDossierContext();
  const [, , foActionsRequises] = useFOFilterContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);

  // display the actions requises banner(s)
  useDisplayActionsRequisesBanner(dossier, foActionsRequises);

  const filesList: ComputedResponseDtoListAvailableFilesEnum[] =
    dossier.computed?.listAvailableFiles ?? [];

  const uniteParent = useRefUniteFo(
    dossier.computed?.uniteFODto?.idCorrelationParent,
  );

  function mapComputedListActionsEnumToAvailableAction(
    actionEnum: ComputedResponseDtoListActionsEnum,
  ): AvailableAction[] {
    switch (actionEnum) {
      case ComputedResponseDtoListActionsEnum.SAISIE_NOTIFICATION_MANUELLE:
        return [
          {
            title: "Suivre l'envoi de la notification",
            onClick: openSaisieInfoNotification,
          },
          {
            title: 'Editer la notification',
            onClick: openEditionNotification,
          },
        ];

      case ComputedResponseDtoListActionsEnum.MODIFIER_COORDONNEES_PROPRIETAIRE:
        return [
          {
            title: 'Modifier les coordonnées du propriétaire',
            onClick: openUpdateInfosProprietaire,
          },
        ];

      case ComputedResponseDtoListActionsEnum.MAIN_LEVEE:
        return [
          {
            title: filesList.includes(
              ComputedResponseDtoListAvailableFilesEnum.MAIN_LEVEE_FO,
            )
              ? 'Modifier la mainlevée'
              : 'Editer la mainlevée',
            onClick: openMainLevee,
          },
        ];

      case ComputedResponseDtoListActionsEnum.PROLONGER_MEF:
        return [
          {
            title: 'Prolonger la mise en fourrière administrative',
            onClick: openProlongation,
          },
        ];

      case ComputedResponseDtoListActionsEnum.CONTROLER_DONNEES_VEHICULE:
        return [
          {
            title: 'Contrôler les données du véhicule',
            onClick: openControlerDonneesVehicule,
          },
        ];
      case ComputedResponseDtoListActionsEnum.RELANCE_VENTE_VOL:
        return [
          {
            title: 'Contrôler la relance de la vente',
            onClick: openRelanceVenteVol,
          },
        ];
      case ComputedResponseDtoListActionsEnum.CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE:
        return [
          {
            title: 'Contrôler le blocage de la vente',
            onClick: controleBlocageVente.open,
          },
        ];
      case ComputedResponseDtoListActionsEnum.UPDATE_ENTITES_RATTACHEMENT_FO_AF:
        return [
          {
            title: 'Modifier les entités de rattachement',
            onClick: updateEntiteRattachementModal.open,
          },
        ];
      case ComputedResponseDtoListActionsEnum.CONFIRMER_VOL_VVNR:
        return [
          {
            title: 'Confirmer le vol',
            onClick: confirmationVolVvnr.open,
          },
        ];
      case ComputedResponseDtoListActionsEnum.VALIDATION_ACTIONS_VVNR_HORS_SIF:
        return [
          {
            title: 'Valider les actions externes liées au vol',
            onClick: validationActionsVvnrHorsSif.open,
          },
        ];
      default:
        return [];
    }
  }

  const actions: AvailableAction[] =
    dossier.computed?.listActions?.flatMap(
      mapComputedListActionsEnumToAvailableAction,
    ) ?? [];

  const responsibleIdCorrelationUniteFo =
    dossier.computed?.uniteFODto?.idCorrelationParent ||
    dossier.computed?.uniteFODto?.idCorrelation;
  const responsibleAbreviationUniteFO =
    uniteParent?.abreviation || dossier.computed?.uniteFODto?.abreviation;

  return (
    <>
      {user.idCorrelationUniteFO === responsibleIdCorrelationUniteFo ? (
        <ActionAndFileWrapper
          filesList={filesList}
          actions={actions}
          dossierId={dossierId}
        />
      ) : (
        <AntdAlert
          className={'noActionsMessage'}
          message={`Aucune action ne peut être effectuée sur ce dossier car il est rattaché à l’unité ${responsibleAbreviationUniteFO}`}
          type={'info'}
          showIcon={true}
        />
      )}

      {/* Action édition main levée */}
      <AntdModal
        visible={isMainLeveeOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={closeMainLeveeOpen}
        maskClosable={false}
        width={680}
        className="modalMainLevee"
      >
        <MainLeveeForm close={closeMainLeveeOpen} />
      </AntdModal>

      {/* Action modification coordonnées propriétaire */}
      <AntdModal
        visible={isUpdateInfosProprietaireOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={closeUpdateInfosProprietaire}
        maskClosable={false}
        width={680}
      >
        <CoordonneesProprietaireForm
          controller={foController}
          close={closeUpdateInfosProprietaire}
          editable={true}
        />
      </AntdModal>

      {/* Action modification coordonnées notification */}
      <AntdModal
        visible={isEditionNotificationOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={closeEditionNotification}
        maskClosable={false}
        width={680}
      >
        <EditionNotificationForm close={closeEditionNotification} />
      </AntdModal>

      {/* prolonger mise en fourriere modal */}
      <ModalProlongation
        visible={isProlongationOpen}
        close={closeProlongation}
      />

      {/* contrôler les données du véhicule modal */}
      <ModalControlerDonneesVehicule
        visible={isControlerDonneesVehiculeOpen}
        close={closeControlerDonneesVehicule}
      />

      {/* contrôle blocage de la vente modal*/}
      <ControleBlocageVenteErreurIdentificationVehicule
        visible={controleBlocageVente.visible}
        close={controleBlocageVente.close}
      ></ControleBlocageVenteErreurIdentificationVehicule>

      {/* renseigner les infos de la notification modal */}
      <ActionSaisieInfoNotification
        visible={isSaisieInfoNotificationOpen}
        close={closeSaisieInfoNotification}
      />

      {/* relancer la vente modal */}
      <AntdModal
        visible={isRelanceVenteVolOpen}
        onCancel={closeRelanceVenteVol}
        destroyOnClose={true}
        footer={null}
        width={700}
        maskClosable={false}
      >
        <RelanceVenteVol close={closeRelanceVenteVol} />
      </AntdModal>
      <AntdModal
        visible={updateEntiteRattachementModal.visible}
        onCancel={updateEntiteRattachementModal.close}
        destroyOnClose={true}
        footer={null}
        width={700}
        maskClosable={false}
      >
        <UpdateEntiteRattachementFO
          closeModal={updateEntiteRattachementModal.close}
        />
      </AntdModal>

      {/* confirmer le vol d'un vvnr */}
      <ConfirmationVolVvnr
        visible={confirmationVolVvnr.visible}
        close={confirmationVolVvnr.close}
      />

      {/* valider les actions externes liées à un vol d'un vvnr */}
      <ValidationActionsVvnrHorsSif
        visible={validationActionsVvnrHorsSif.visible}
        close={validationActionsVvnrHorsSif.close}
      />
    </>
  );
};

export default ActionsSuiviVehicule;
