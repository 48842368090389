import React from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';

import { useConfirmVolVvnr } from 'hooks/dossiers/useConfirmVolVvnr';

import './ConfirmationVolVvnr.less';

interface RelanceVenteVolProps {
  visible: boolean;
  close: () => void;
}

export const ConfirmationVolVvnr = ({
  visible,
  close,
}: RelanceVenteVolProps): React.ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const [submit, submitInProgress] = useConfirmVolVvnr(
    dossierId,
    setDossier,
    close,
  );

  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      destroyOnClose={true}
      footer={null}
      width={700}
      maskClosable={false}
    >
      <div className="confirmationVolVvnr">
        <h3>
          Confirmer le caractère volé d&apos;un véhicule après interrogation
          ARGOS
        </h3>
        <p>
          Ce dossier répond à plusieurs caractéristiques d’un dossier volé
          recherché par un assureur.
          <br />
          Afin de débloquer la situation, vous devez confirmer le vol
        </p>

        <p className="confirmationVolVvnrConsequences">
          <span>
            <b>Conséquence de l’action</b>
          </span>
          <br />
          <span>
            Une fois le caractère volé du véhicule confirmé, le processus de
            restitution au propriétaire pourra être entamé.
          </span>
        </p>
        <div className="buttonsContainer">
          <AntdButton onClick={close}>Annuler</AntdButton>
          <AntdButton
            type="primary"
            disabled={submitInProgress}
            onClick={submit}
          >
            Confirmer
          </AntdButton>
        </div>
      </div>
    </AntdModal>
  );
};
