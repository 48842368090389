import { InfosSivVehiculeRequestDto } from 'lib_api/lib/api/gen';

import { VALUE_NON_REFERENCE } from 'utils/global';
import { InfosSivVehiculeFormValues } from '../types';

export function infosVehiculeFormValuesToRequestDto(
  infosVehicule: InfosSivVehiculeFormValues,
): InfosSivVehiculeRequestDto {
  return {
    idCorrelationGenre: infosVehicule.idCorrelationGenre || null,
    numeroImmatriculation: infosVehicule.numeroImmatriculation || null,
    vin: infosVehicule.vin || null,
    idCorrelationMarque:
      infosVehicule.marque?.value === VALUE_NON_REFERENCE
        ? null
        : infosVehicule.marque?.option?.idCorrelation || null,
    idCorrelationModele:
      infosVehicule.idCorrelationModele === VALUE_NON_REFERENCE
        ? null
        : infosVehicule.idCorrelationModele || null,
    cnit: infosVehicule.cnit || null,
    datePremiereImmatriculation:
      infosVehicule.datePremiereImmatriculation || null,
  };
}
