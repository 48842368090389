import React from 'react';

import {
  AutoriteFourriereDto,
  AutoriteFourriereViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import ModalEditionAutoriteFourriere from './ModalEditionAutoriteFourriere';
import BaseInputNumber from 'components/BaseForm/BaseInputNumber';

import { AutoriteFourriereFormValues } from '../type';
import {
  buildFormValues,
  useSubmitUpdateAutoriteFourriere,
  useValidateUpdateAutoriteFourriere,
} from '../utils';

interface ModalUpdateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
  autorite: AutoriteFourriereDto;
}

function ModalUpdate({
  close,
  visible,
  refreshDashboard,
  autorite,
}: ModalUpdateProps): React.ReactElement {
  const initials: AutoriteFourriereFormValues = buildFormValues(autorite);
  const { submit, inProgress } = useSubmitUpdateAutoriteFourriere(
    autorite.idCorrelation,
    refreshDashboard,
    close,
  );
  const { validate } = useValidateUpdateAutoriteFourriere(
    autorite.idCorrelation,
  );

  return (
    <ModalEditionAutoriteFourriere
      close={close}
      visible={visible}
      title="Mise à jour de l'autorité de fourrière"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
      additionalInputs={[
        {
          field: AutoriteFourriereViolationDtoFormFieldEnum.ID_HERMES,
          name: 'idHermes',
          tooltipProps: {
            title:
              'Attention: toute modification de cet ID créé par la DNID aura des conséquences sur les flux de remises de véhicules à vendre.',
            trigger: ['focus'],
            placement: 'top',
          },
          label: 'Id Hermes',
          render: () => {
            return <BaseInputNumber />;
          },
        },
        {
          field:
            AutoriteFourriereViolationDtoFormFieldEnum.ID_HERMES_UTILISATEUR,
          name: 'idHermesUtilisateur',
          label: 'Id Hermes utilisateur remettant',
        },
      ]}
    />
  );
}

export default ModalUpdate;
