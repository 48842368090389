import {
  InfosSivDocumentRequestDto,
  InfosSivSituationAdministrativeRequestDto,
} from 'lib_api/lib/api/gen';

import { InfosSivDocumentsAndSituationAdministrativeFormValues } from '../types';

export function infosDocumentFormValuesToRequestDto(
  infosDocument: InfosSivDocumentsAndSituationAdministrativeFormValues,
): InfosSivDocumentRequestDto {
  return {
    dateCi: infosDocument.dateCi || null,
    numeroFormule: infosDocument.numeroFormule || null,
  };
}

export function infosSituationAdministrativeFormValuesToRequestDto(
  infosSituationAdministrative: InfosSivDocumentsAndSituationAdministrativeFormValues,
): InfosSivSituationAdministrativeRequestDto {
  return {
    flagPve: infosSituationAdministrative.flagPve || null,
    flagVehiculeVole: infosSituationAdministrative.flagVehiculeVole || null,
  };
}
