import React from 'react';

import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';

interface CommentairesFieldProps {
  currentCommentaires: string | null;
  setCurrentCommentaires: (value: string) => void;
}

function CommentairesField({
  currentCommentaires: currentCommentaires,
  setCurrentCommentaires: setCurrentCommentaires,
}: CommentairesFieldProps): React.ReactElement {
  return (
    <FormItem label="Commentaires">
      <TextArea
        value={currentCommentaires ?? ''}
        onChange={event => {
          setCurrentCommentaires(event.target.value);
        }}
      />
    </FormItem>
  );
}

export default CommentairesField;
