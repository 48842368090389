import React, { useCallback, useState } from 'react';
import moment from 'moment';

import {
  ConditionsReactivateFourriereDto,
  FourriereDto,
  AdminFonctionnelControllerApi,
  FourriereRequestDto,
  FourriereViolationDtoFormFieldEnum,
  ReferentielSearchResultDtoFourriereDto,
  ReferentielUsesDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import {
  FetchDataResult,
  TableHeaderFunctionWithoutSort,
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import {
  FourriereFormValues,
  FilterDashboardFourriere,
} from 'types/referentiels/Fourriere';
import { FourriereSort } from 'enums/referentiels/Fourriere';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { SubmitFunction, ValidateFunction } from 'components/BaseForm/types';

export const buildHeader: TableHeaderFunctionWithoutSort<
  FourriereDto,
  FilterDashboardFourriere
> = (fetchedData, _selectedData, filter, setFilter) => (
  <DashboardHeader
    totalCount={fetchedData !== undefined ? fetchedData.total : 0}
    filter={filter}
    setFilter={setFilter}
  />
);

/**
 * Hook sent to generic search hook to send search request and convert returned result to readable data
 */
export function useSearchFourriere(
  filter: FilterDashboardFourriere,
  sort: TableSort<FourriereSort>,
  pagination: TablePagination,
): [
  () => Promise<ReferentielSearchResultDtoFourriereDto>,
  (
    result: ReferentielSearchResultDtoFourriereDto,
  ) => FetchDataResult<FourriereDto>,
] {
  const controller = useApi().ReferentielControllerApi;

  return [
    () => {
      return controller.searchFourriereUsingSpecsUsingGET({
        filters: {
          ...filter,
          page: pagination.page,
          pageSize: pagination.pageSize,
          commune: filter.commune?.commune,
        },
        sorts: {
          codePostal: sort?.CODE_POSTAL,
          commune: sort?.COMMUNE,
          raisonSociale: sort?.RAISON_SOCIALE,
          raisonSocialeDnid: sort?.RAISON_SOCIALE_DNID,
        },
      });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

export function useChangeValidityFourriere(
  controller: AdminFonctionnelControllerApi,
  currentCommentaires: string | null,
): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<ConditionsReactivateFourriereDto>,
  (idCorrelation: string) => Promise<ReferentielUsesDto>,
] {
  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateFourriereUsingPUT(idCorrelation, {
        commentaires: currentCommentaires,
      });
    },
    [controller, currentCommentaires],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateFourriereUsingPUT(idCorrelation, {
        commentaires: currentCommentaires,
      });
    },
    [controller, currentCommentaires],
  );

  const conditionsReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<ConditionsReactivateFourriereDto> => {
      return controller.conditionReactivationFourriereUsingGET(idCorrelation);
    },
    [controller],
  );

  const getUsesRequest = useCallback(
    (idCorrelation: string): Promise<ReferentielUsesDto> => {
      return controller.searchUsesFourriereUsingGET(idCorrelation);
    },
    [controller],
  );

  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    getUsesRequest,
  ];
}

export const buildFormValues = (
  fourriere: FourriereDto,
): FourriereFormValues => {
  return {
    raisonSociale: fourriere.raisonSociale,
    numeroAgrement: fourriere.numeroAgrement ?? undefined,
    dateAgrement: fourriere.dateAgrement
      ? moment(fourriere.dateAgrement)
      : undefined,
    finAgrement: fourriere.finAgrement
      ? moment(fourriere.finAgrement)
      : undefined,
    listAf: fourriere.autoritesFourrieres.map(
      autorite => autorite.idCorrelation,
    ),
    nbPlaces: fourriere.nombreEmplacements ?? undefined,
    numeroVoie: fourriere.noVoie ?? undefined,
    repetition: fourriere.repetition ?? undefined,
    adresse: fourriere.adresse ?? undefined,
    complementAdresse: fourriere.complementAdresse ?? undefined,
    codePostal: fourriere.codePostal ?? undefined,
    commune: fourriere.commune ?? undefined,
    telephoneFixe: fourriere.telephoneFixe ?? undefined,
    telephonePortable: fourriere.telephoneMobile ?? undefined,
    email: fourriere.adresseMail ?? undefined,
    gps: fourriere.coordonneesGPS ?? undefined,
    raisonSocialeDnid: fourriere.raisonSocialeDnid ?? undefined,
    adresseDnid: fourriere.adresseDnid ?? undefined,
    nomResponsable: fourriere.nomResponsable ?? undefined,
    emailResponsable: fourriere.adresseMailResponsable ?? undefined,
    idHermes: fourriere.idHermes ?? undefined,
    commentaires: fourriere.commentaires ?? undefined,
  };
};

export const buildRequestDto = (
  formValues: FourriereFormValues,
): FourriereRequestDto => {
  return {
    dateAgrement:
      formValues.dateAgrement?.format(UNIX_TIMESTAMP_FORMAT) || null,
    finAgrement: formValues.finAgrement?.format(UNIX_TIMESTAMP_FORMAT) || null,
    adresse: formValues.adresse || null,
    adresseDnid: formValues.adresseDnid || null,
    codePostal: formValues.codePostal || null,
    commune: formValues.commune || null,
    complementAdresse: formValues.complementAdresse || null,
    gps: formValues.gps || null,
    email: formValues.email || null,
    emailResponsable: formValues.emailResponsable || null,
    nbPlaces: formValues.nbPlaces || null,
    nomResponsable: formValues.nomResponsable || null,
    numeroAgrement: formValues.numeroAgrement || null,
    numeroVoie: formValues.numeroVoie || null,
    raisonSociale: formValues.raisonSociale || null,
    raisonSocialeDnid: formValues.raisonSocialeDnid || null,
    repetition: formValues.repetition || null,
    telephoneFixe: formValues.telephoneFixe || null,
    telephonePortable: formValues.telephonePortable || null,
    listIdCorrelationAf: formValues.listAf || null,
    idHermes: formValues.idHermes || null,
    commentaires: formValues.commentaires || null,
  };
};

export function useValidateCreateFourriere(): ValidateFunction<
  FourriereFormValues,
  FourriereViolationDtoFormFieldEnum
> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: FourriereViolationDtoFormFieldEnum,
      values: FourriereFormValues,
    ) => {
      const response = await controller.validateCreateFourriereFieldUsingPOST(
        field,
        buildRequestDto(values),
      );

      const violations = response.fourriereViolationsDto ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitCreateFourriere(
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<FourriereFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: FourriereFormValues) => {
      setInProgress(true);
      return controller
        .createFourriereUsingPOST(buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}

export function useValidateUpdateFourriere(
  autoriteFourriereId: string,
): ValidateFunction<FourriereFormValues, FourriereViolationDtoFormFieldEnum> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: FourriereViolationDtoFormFieldEnum,
      values: FourriereFormValues,
    ) => {
      const response = await controller.validateUpdateFourriereFieldUsingPOST(
        field,
        autoriteFourriereId,
        buildRequestDto(values),
      );

      const violations = response.fourriereViolationsDto ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitUpdateFourriere(
  fourriereId: string,
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<FourriereFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: FourriereFormValues) => {
      setInProgress(true);
      return controller
        .updateFourriereUsingPUT(fourriereId, buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}
