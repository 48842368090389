import React, { useState } from 'react';
import {
  Button as AntdButton,
  Menu as AntdMenu,
  Dropdown as AntdDropdown,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
  ConditionsReactivateFourriereDto,
  FourriereDto,
} from 'lib_api/lib/api/gen';

import ModalDetails from './Modals/ModalDetails';
import { useApi } from 'hooks/ApiStoreContext';
import { useChangeValidityFourriere } from './utils';
import ModalDesactivation from '../Modals/ModalDesactivation/ModalDesactivation';
import ModalReactivation from '../Modals/ModalReactivation/ModalReactivation';
import CommentairesField from '../Modals/CommentairesField';
import UsesFourriere from './UsesFourriere/UsesFourriere';
import ConditionReactivateFourriere from './ConditionReactivateFourriere/ConditionReactivateFourriere';
import ModalUpdate from './Modals/ModalUpdate';

import './CellDetailsAndActions.less';

interface CellDetailsAndActionsProps {
  fourriere: FourriereDto;
  refreshDashboard: () => void;
}

function CellDetailsAndActions({
  fourriere,
  refreshDashboard,
}: CellDetailsAndActionsProps): React.ReactElement {
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
  const [isChangeValidityOpen, setIsChangeValidityOpen] =
    useState<boolean>(false);
  const controller = useApi().AdminFonctionnelControllerApi;

  const [currentCommentaires, setCurrentCommentaires] = useState<string | null>(
    fourriere.commentaires,
  );

  const closeUpdateValidity = () => {
    setIsChangeValidityOpen(false);
  };

  const [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    getUsesRequest,
  ] = useChangeValidityFourriere(controller, currentCommentaires);

  const conditionUnlock = (
    conditions: ConditionsReactivateFourriereDto | undefined,
  ): boolean => {
    return conditions?.reactivationAvailable ?? false;
  };

  const menu = (
    <AntdMenu>
      <AntdMenu.Item
        onClick={() => {
          setIsUpdateOpen(true);
        }}
      >
        Modifier
      </AntdMenu.Item>
      <AntdMenu.Item
        onClick={() => {
          setIsChangeValidityOpen(true);
        }}
      >
        {fourriere.enabled
          ? 'Désactiver la fourrière'
          : 'Réactiver la fourrière'}
      </AntdMenu.Item>
    </AntdMenu>
  );

  return (
    <>
      <AntdButton
        onClick={() => {
          setIsDetailsOpen(true);
        }}
      >
        Détails
      </AntdButton>
      <ModalDetails
        fourriere={fourriere}
        visible={isDetailsOpen}
        close={() => {
          setIsDetailsOpen(false);
        }}
      />
      <ModalUpdate
        refreshDashboard={refreshDashboard}
        fourriere={fourriere}
        visible={isUpdateOpen}
        close={() => {
          setIsUpdateOpen(false);
        }}
      />
      <AntdDropdown overlay={menu} className="menuActions">
        <AntdButton>
          Actions <DownOutlined />
        </AntdButton>
      </AntdDropdown>
      {isChangeValidityOpen &&
        (fourriere.enabled ? (
          <ModalDesactivation
            idCorrelation={fourriere.idCorrelation}
            titleModal="Désactiver la fourrière"
            usesRequest={getUsesRequest}
            submitDeprecate={submitDeprecateReferentiel}
            componentUses={UsesFourriere}
            visible={isChangeValidityOpen}
            close={closeUpdateValidity}
            callAfterSubmit={refreshDashboard}
          >
            <CommentairesField
              currentCommentaires={currentCommentaires}
              setCurrentCommentaires={setCurrentCommentaires}
            />
          </ModalDesactivation>
        ) : (
          <ModalReactivation
            idCorrelation={fourriere.idCorrelation}
            titleModal="Réactiver la fourrière"
            visible={isChangeValidityOpen}
            close={closeUpdateValidity}
            callAfterSubmit={refreshDashboard}
            conditionRequest={conditionsReactivateReferentiel}
            componentConditions={ConditionReactivateFourriere}
            conditionUnlock={conditionUnlock}
            reactivateRequest={submitReactivateReferentiel}
          >
            <CommentairesField
              currentCommentaires={currentCommentaires}
              setCurrentCommentaires={setCurrentCommentaires}
            />
          </ModalReactivation>
        ))}
    </>
  );
}

export default CellDetailsAndActions;
