import React from 'react';
import { message as AntdMessage } from 'antd';

import {
  DossierControllerApi,
  DossierResponseDto,
  ForceOrdreControllerApi,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import {
  SubmitFunction,
  UpdateEntiteRattachementFormValues,
} from 'components/UpdateEntitesRattachements/types';
import { displayNotificationForOthersIfDoublonOrElseThrow } from 'components/UpdateEntitesRattachements/utils';

function displaySuccessfulMessage(
  response: DossierResponseDto,
): DossierResponseDto {
  void AntdMessage.success(
    'Les entités de rattachement ont bien été modifiées',
  );
  return response;
}

function buildSubmitFunction(
  refController: ForceOrdreControllerApi,
  dossierController: DossierControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
): SubmitFunction {
  return (values: UpdateEntiteRattachementFormValues) => {
    return refController
      .updateEntitesRattachementFOUsingPOST(dossierId, values)
      .then(displaySuccessfulMessage)
      .catch((response: Response) =>
        displayNotificationForOthersIfDoublonOrElseThrow(
          response,
          dossierController,
          dossierId,
        ),
      )
      .then(setDossier)
      .then(closeModal)
      .catch((error: Response) => {
        void backAlertMessage(error);
      });
  };
}

export default buildSubmitFunction;
