import React from 'react';
import { InformationBanner } from 'components/Banner';
import { ComputedResponseDtoListActionsRequisesEnum } from 'lib_api/lib/api/gen';
import { useDossierActionsRequisesContext } from 'hooks/DossierActionsRequisesContext';

const TEXT_CLICK_HERE_RELANCE_VENTE =
  'Cliquer sur "Contrôler le blocage de la vente" pour la relancer';

function mapActionRequiseToDescription(
  actionRequise: ComputedResponseDtoListActionsRequisesEnum,
): string | null {
  switch (actionRequise) {
    case ComputedResponseDtoListActionsRequisesEnum.CLASSER_MANUELLEMENT:
      return (
        `Le classement n'a pas pu être effectué automatiquement. ` +
        `Compléter les informations du véhicule en cliquant sur ` +
        `"Modifier les données techniques du véhicule".`
      );
    case ComputedResponseDtoListActionsRequisesEnum.SAISIE_NOTIFICATION_MANUELLE:
      return (
        'Editer la notification pour ce véhicule puis renseigner ' +
        "la date d'envoi du courrier."
      );
    case ComputedResponseDtoListActionsRequisesEnum.SAISIE_VHU:
      return (
        'Le véhicule est à détruire. ' +
        "Renseigner le centre VHU puis télécharger le bon d'enlèvement."
      );
    case ComputedResponseDtoListActionsRequisesEnum.MODIFIER_COORDONNEES_PROPRIETAIRE:
      return (
        'Les coordonnées du propriétaire ne sont pas suffisantes pour le notifier. ' +
        'Compléter ses informations en cliquant sur "Modifier les coordonnées du propriétaire".'
      );
    case ComputedResponseDtoListActionsRequisesEnum.RENSEIGNER_STATUT_RETOUR_NOTIFICATION_MANUELLE:
      return (
        'La notification a été envoyée il y a 20 jours. ' +
        'Renseigner la date et le statut de retour de notification.'
      );
    case ComputedResponseDtoListActionsRequisesEnum.PROLONGER_MEF:
      return 'Renseigner la prolongation de mise en fourrière administrative. Cliquer sur "Prolonger la mise en fourrière administrative".';
    case ComputedResponseDtoListActionsRequisesEnum.ENREGISTRER_ENTREE:
      return 'Le dossier doit être complété pour finaliser l\'enregistrement du véhicule. Cliquer sur "Enregistrer l\'entrée"';
    case ComputedResponseDtoListActionsRequisesEnum.CONTROLER_DONNEES_VEHICULE:
      return 'Un doute a été émis sur la fiabilité des données du véhicule. Cliquer sur "Contrôler les données" pour les vérifier et/ou corriger.';
    case ComputedResponseDtoListActionsRequisesEnum.CONTROLE_DONNEES_ERREUR_IDENTIFICATION_VEHICULE:
      return (
        "Le commissaire aux ventes du Domaine a bloqué la vente de ce véhicule après avoir relevé une erreur d'identification. " +
        TEXT_CLICK_HERE_RELANCE_VENTE
      );
    case ComputedResponseDtoListActionsRequisesEnum.RELANCE_VENTE_VOL:
      return (
        "Le commissaire aux ventes du Domaine a bloqué la vente de ce véhicule après l'avoir identifié comme volé dans le SIV. " +
        TEXT_CLICK_HERE_RELANCE_VENTE
      );
    case ComputedResponseDtoListActionsRequisesEnum.RELANCE_VENTE_IMMO_PJ:
      return (
        'La vente du véhicule a été bloquée par le CAV des Domaines car une mention Immo PJ y est apposée. ' +
        TEXT_CLICK_HERE_RELANCE_VENTE
      );
    case ComputedResponseDtoListActionsRequisesEnum.RELANCE_VENTE_OPPOSITION:
      return (
        'La vente du véhicule a été bloquée par le CAV des Domaines car une mention Opposition y est apposée. ' +
        TEXT_CLICK_HERE_RELANCE_VENTE
      );
    case ComputedResponseDtoListActionsRequisesEnum.RELANCE_VENTE_DOUBLON:
      return (
        'La vente du véhicule a été bloquée par le CAV des Domaines qui a identifié un potentiel cas de doublon. ' +
        TEXT_CLICK_HERE_RELANCE_VENTE
      );
    case ComputedResponseDtoListActionsRequisesEnum.RELANCE_VENTE_MANQUANT:
      return (
        'La vente du véhicule a été bloquée par le CAV des Domaines, le véhicule étant considéré comme manquant. ' +
        TEXT_CLICK_HERE_RELANCE_VENTE
      );
    case ComputedResponseDtoListActionsRequisesEnum.CONTROLE_BLOCAGE_ARGOS:
      return "Le délai d'attente de retour d'ARGOS est anormalement long. Une investigation dédiée - potentiellement avec le SI partenaire - est nécessaire afin de comprendre l'origine de cette attente.";
    case ComputedResponseDtoListActionsRequisesEnum.CONTROLE_BLOCAGE_ANTAI:
      return "Le délai d'attente de l'accusé de remise en poste (ANTAI) est anormalement long. Une investigation dédiée - potentiellement avec le SI partenaire - est nécessaire afin de comprendre l'origine de cette attente.";
    case ComputedResponseDtoListActionsRequisesEnum.CONTROLE_BLOCAGE_DNID:
      return "Le délai d'attente de la vente/refus de ce véhicule par la DNID est anormalement long. Une investigation dédiée - potentiellement avec le SI partenaire - est nécessaire afin de comprendre l'origine de cette attente.";
    case ComputedResponseDtoListActionsRequisesEnum.CONFIRMER_VOL_VVNR:
      return (
        'Ce véhicule est recherché par un assureur suite à un vol déclaré. ' +
        'Cliquez sur "Confirmer le vol" pour confirmer le vol du véhicule'
      );
    default:
      console.warn('Unhandled ActionRequise in banner', actionRequise);
      return null;
  }
}

export const DossierActionRequiseBanner = (): React.ReactElement => {
  const { dossierActionsRequises } = useDossierActionsRequisesContext();

  if (dossierActionsRequises.length === 0) {
    return <></>;
  }
  return (
    <>
      {dossierActionsRequises.map(actionRequise => {
        const description = mapActionRequiseToDescription(actionRequise);
        if (description !== null) {
          return (
            <InformationBanner
              description={description}
              alertType={'warning'}
              key={actionRequise.toString()}
              closable={false}
            />
          );
        }
        return null;
      })}
    </>
  );
};
