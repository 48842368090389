import React from 'react';
import { ArgsProps } from 'antd/lib/notification';
import { ErrorDto } from 'lib_api/lib/api/gen';
import { backErrorMessages as messages } from 'utils/ErrorMessages';
import { message as AntdMessage, notification as AntdNotification } from 'antd';
import {
  BackErrorMessages,
  displayableBackErrorMessages,
} from 'types/BackErrorMessages';

export interface Error {
  code?: number;
  description: BackErrorMessages[keyof BackErrorMessages];
}

/*
 * <!> WARNING <!> : if ErrorDto structure changes,
 * this function will have to be changed
 */
export function isErrorBodyAnErrorDto(
  errorBody: unknown,
): errorBody is ErrorDto {
  return (
    errorBody !== undefined &&
    errorBody !== null &&
    typeof errorBody === 'object' &&
    (errorBody as ErrorDto).code !== undefined &&
    (errorBody as ErrorDto).message !== undefined &&
    (errorBody as ErrorDto).localized !== undefined
  );
}

export function popErrorMessage(message: string): void {
  void AntdMessage.error(message);
}

/**
 * Open a notification box within which the whole message is displayed.
 */
export const openNotification = (
  message: string,
  error: Error,
  isError = true,
): void => {
  // cut to keep the first errors
  const MAX_LINES = 5;
  const lines: string[] = error.description.split('\n');
  const cut: string[] = lines.slice(0, MAX_LINES);
  if (lines.length > MAX_LINES) {
    cut.push(`+ ${lines.length - MAX_LINES} autres...`);
  }

  const argsNotification: ArgsProps = {
    message: message,
    // build the multiline message
    description: cut.map((value, index) => (
      <span key={index}>
        {value}
        <br />
      </span>
    )),
    style: {
      width: 600,
    },
    duration: 0, // won't close automatically
    placement: 'topLeft',
  };

  isError
    ? AntdNotification.error(argsNotification)
    : AntdNotification.info(argsNotification);
};

/*
 * Simple function to display a message at the top screen
 */
function popAPIErrorMessage({ code, description }: Error): void {
  popErrorMessage(`Erreur ${code ?? 'inconnue'}: ${description}`);
}

/*
 * Manage the display of a convenient error message when there is a API error
 */
export async function backAlertMessage(
  response: Response,
  displayNotification = false, // should the whole error message be displayed by default ?
  displayNotificationOnError = true, // should the error message be displayed by default ?
): Promise<Error> {
  let errorBody;
  try {
    errorBody = await response.json();
  } catch (e) {
    console.error(e);
  }

  const error: Error = {
    code: response.status,
    description: isErrorBodyAnErrorDto(errorBody)
      ? displayableBackErrorMessages.includes(errorBody.code)
        ? errorBody.message // TODO MINTF-1666 Don't use errorDetail
        : messages[errorBody.code]
      : messages.default,
  };

  if (displayNotification) {
    openNotification(`Impossible de lire le fichier`, error);
  } else if (displayNotificationOnError) {
    popAPIErrorMessage(error);
  }

  return error;
}
