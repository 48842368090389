import React, { ReactElement } from 'react';
import {
  Alert as AntdAlert,
  Button as AntdButton,
  Modal as AntdModal,
} from 'antd';

import { ReferentielUsesDto } from 'lib_api/lib/api/gen';

import { useChangeValidity, useReferentielUses } from '../../utils';

import './ModalDesactivation.less';

export interface UsesReferentielProps {
  uses: ReferentielUsesDto;
}

interface ModalDesactivationProps<ResponseType> {
  idCorrelation: string;
  titleModal: string;
  componentUses:
    | (({ uses }: UsesReferentielProps) => React.ReactElement)
    | undefined;
  visible: boolean;
  close: () => void;
  callAfterSubmit: () => void;
  usesRequest:
    | ((idCorrelation: string) => Promise<ReferentielUsesDto>)
    | undefined;
  submitDeprecate: (idCorrelation: string) => Promise<ResponseType>;
  children?: ReactElement;
}

function ModalDesactivation<ResponseType>({
  idCorrelation,
  titleModal,
  componentUses,
  visible,
  close,
  callAfterSubmit,
  submitDeprecate,
  usesRequest,
  children,
}: ModalDesactivationProps<ResponseType>): React.ReactElement {
  const [uses, isLoadingUses, unlockedDesactivation] = useReferentielUses(
    idCorrelation,
    usesRequest,
  );

  const [submitDesactivation, isLoadingSubmit] = useChangeValidity(
    idCorrelation,
    () => {
      close();
      callAfterSubmit();
    },
    submitDeprecate,
  );

  return (
    <AntdModal
      className="modalValidity"
      visible={visible}
      onCancel={close}
      footer={null}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
    >
      <h3>{titleModal}</h3>
      {children}
      {uses !== undefined &&
        componentUses !== undefined &&
        !unlockedDesactivation && (
          <AntdAlert
            type="warning"
            className="alertUsed"
            message={componentUses({ uses: uses })}
          />
        )}
      <div className="modalActions">
        <AntdButton onClick={close}>Annuler</AntdButton>
        {
          <AntdButton
            onClick={submitDesactivation}
            disabled={!unlockedDesactivation || isLoadingSubmit}
            loading={isLoadingUses || isLoadingSubmit}
            type="primary"
          >
            Confirmer la désactivation
          </AntdButton>
        }
      </div>
    </AntdModal>
  );
}

export default ModalDesactivation;
