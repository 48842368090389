import { useState } from 'react';

import { DossierDtoAdminFonctionnel } from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useApi } from 'hooks/ApiStoreContext';
import { InfosSivFormValues } from './types';
import { InfosSivFormValuesToRequest } from './utils';

type SubmitFunction = {
  submit: (dossierId: string, values: InfosSivFormValues) => void;
  inProgress: boolean;
};

function useSubmitUpdateInfosSiv(
  onSuccess: (newDossier: DossierDtoAdminFonctionnel) => void,
): SubmitFunction {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();
  return {
    submit: (dossierId, values) => {
      setInProgress(true);
      const body = InfosSivFormValuesToRequest(values);
      return controller
        .updateInfosSivManuelUsingPOST(dossierId, body)
        .then(onSuccess)
        .catch(backAlertMessage)
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress: inProgress,
  };
}

export default useSubmitUpdateInfosSiv;
