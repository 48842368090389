import React, { useCallback } from 'react';

import { useApi } from 'hooks/ApiStoreContext';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useDossierCompletAdmin } from 'hooks/dossierAdmin/useDownloadDossierAdminFonctionnel';
import UpdateEntiteRattachement from 'components/UpdateEntitesRattachements/UpdateEntiteRattachement';
import useInitialValues from 'components/UpdateEntitesRattachements/useInitialValues';
import buildValidateFunction from './buildValidateFunction';
import buildSubmitFunction from './buildSubmitFunction';

interface UpdateEntiteRattachementAdminProps {
  closeModal: () => void;
}

function UpdateEntiteRattachementAdmin({
  closeModal,
}: UpdateEntiteRattachementAdminProps): React.ReactElement {
  const [{ result, setDossier }, { fetchEvents }] = useDossierAdminContext();
  const dossierId = result.id;
  const { dossier: dossierAdmin } = useDossierCompletAdmin(dossierId);
  const refController = useApi().AdminFonctionnelControllerApi;
  const initialValues = useInitialValues(dossierAdmin?.dossier);

  const validateFunction = useCallback(
    buildValidateFunction(refController, dossierId),
    [refController, dossierId],
  );
  const submitFunction = useCallback(
    buildSubmitFunction(
      refController,
      dossierId,
      setDossier,
      fetchEvents,
      closeModal,
    ),
    [refController, dossierId],
  );

  return (
    <UpdateEntiteRattachement
      closeModal={closeModal}
      initialValues={initialValues}
      loading={!dossierAdmin}
      submitFunction={submitFunction}
      validateFunction={validateFunction}
    />
  );
}

export default UpdateEntiteRattachementAdmin;
