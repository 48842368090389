import { DossierResponseDto } from 'lib_api/lib/api/gen';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useBoolean } from 'utils/genericUtils';
import { useApi } from '../ApiStoreContext';

export function useConfirmVolVvnr(
  dossierId: string,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
): [() => void, boolean] {
  const { ForceOrdreControllerApi } = useApi();
  const {
    value: inProgress,
    setIsTrue: setInProgress,
    setIsFalse: setNotInProgress,
  } = useBoolean(false);
  return [
    () => {
      setInProgress();
      return ForceOrdreControllerApi.confirmVolVvnrUsingPOST(dossierId)
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setNotInProgress();
        });
    },
    inProgress,
  ];
}
