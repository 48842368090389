import { useState, useEffect } from 'react';

import { UniteFOResponseDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

export const useRefUniteFo = (
  idCorrelation: string | null | undefined,
): UniteFOResponseDto | undefined => {
  const behaviourOnError = useHandleBackErrors();

  const [refUniteFo, setReferentielUniteFo] = useState<
    UniteFOResponseDto | undefined
  >(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (idCorrelation && refUniteFo === undefined) {
      referentielControllerApi
        .getValidUniteFOByIdCorrelationUsingGET(idCorrelation)
        .then(fetchedReferentiel => setReferentielUniteFo(fetchedReferentiel))
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    } else if (!idCorrelation && refUniteFo) {
      setReferentielUniteFo(undefined);
    }
  }, [referentielControllerApi, behaviourOnError, refUniteFo, idCorrelation]);

  return refUniteFo;
};
