import React from 'react';
import { message as AntdMessage } from 'antd';

import {
  AdminFonctionnelControllerApi,
  DossierDtoAdminFonctionnel,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import {
  SubmitFunction,
  UpdateEntiteRattachementFormValues,
} from 'components/UpdateEntitesRattachements/types';
import { displayNotificationForAdminIfDoublonOrElseThrow } from 'components/UpdateEntitesRattachements/utils';

function displaySuccessfulMessage(
  response: DossierDtoAdminFonctionnel,
): DossierDtoAdminFonctionnel {
  void AntdMessage.success(
    'Les entités de rattachement ont bien été modifiées',
  );
  return response;
}

function buildSubmitFunction(
  refController: AdminFonctionnelControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierDtoAdminFonctionnel) => void,
  fetchEvents: () => Promise<void>,
  closeModal: () => void,
): SubmitFunction {
  return (values: UpdateEntiteRattachementFormValues) => {
    return refController
      .updateEntitesRattachementUsingPOST(dossierId, values)
      .then(displaySuccessfulMessage)
      .catch((response: Response) =>
        displayNotificationForAdminIfDoublonOrElseThrow(
          response,
          refController,
          dossierId,
        ),
      )
      .then(setDossier)
      .then(fetchEvents)
      .then(closeModal)
      .catch((error: Response) => {
        void backAlertMessage(error);
      });
  };
}

export default buildSubmitFunction;
